import ReviewIcon from '@mui/icons-material/Comment';
import ReviewCreate from './ReviewCreate';
import ReviewList from './ReviewList';
import ReviewShow from './ReviewShow';

export default {
    icon: ReviewIcon,
    list: ReviewList,
    create: ReviewCreate,
    show: ReviewShow,
};
