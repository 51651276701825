import VisitorIcon from '@mui/icons-material/People';
import ClassPresenceList from './ClassPresenceList';

import CreateClassPresence from './CreateClassPresence';
import EditClassPresence from './EditClassPresence';
import ShowClassPresence from './ShowClassPresence';

const resource = {
  list: ClassPresenceList,
  create: CreateClassPresence,
  edit: EditClassPresence,
  show: ShowClassPresence,
  icon: VisitorIcon,
};

export default resource;
