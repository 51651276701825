// @ts-nocheck
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Views,
} from "react-big-calendar";
// Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
// Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
//import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { Grid } from "@material-ui/core";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
//import './styles.css'
import lang from "./globalize/lang";
// require('globalize/lib/cultures/globalize.culture.en-GB')
// require('globalize/lib/cultures/globalize.culture.es')
// require('globalize/lib/cultures/globalize.culture.fr')
// require('globalize/lib/cultures/globalize.culture.ar-TN')
//import 'globalize/lib/cultures/globalize.culture.ar-TN'

import "moment/locale/ar-tn";
import "moment/locale/fr";
import { useGetList } from "react-admin";
import CustomEvent from "./customEvent ";
import DragableItem from "./dragableItem";

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

// const adjEvents = events.map((it, ind) => ({
//   ...it,
//   isDraggable: ind % 2 === 0,
// }))

const formatName = (name, count) => `${name} ID ${count}`;

const CalenderCreate = ({ myEvents, setMyEvents }) => {
  // const [columns, setColumns] = useState(columnsFromBackend)
  //setColumns(columnsFromBackend)
  let calendarData = [];

  const {
    data: teacherList,
    isLoading: loadTeacher,
    error: errorTeacher,
  } = useGetList("teachers", {});

  const { data: coursesList, isLoading: loadCours } = useGetList("courses", {});

  // useEffect(() => {
  //   let currentWeekDay = moment().isoWeekday()
  //   // today = mm + '/' + dd + '/' + yyyy
  //   //console.log('calendarData', calendarData)
  //   if (calendarData && calendarData.length > 0) {
  //     adjEvents = calendarData.map((ev, ind) => {
  //       // const newDate = today.setDate(
  //       //   today.getDate() + (ev.weekDay - currentWeekDay),
  //       // )
  //       const newDate = moment().add(ev.weekDay - currentWeekDay, 'd')
  //       // //console.log('newDate', newDate)
  //       let startTime = ev.startTime.trim().split(':')
  //       let endTime = ev.endTime.trim().split(':')

  //       if (
  //         typeof teacherList === 'undefined' ||
  //         typeof coursesList === 'undefined'
  //       )
  //         return

  //       let teacher = teacherList.filter((x) => x.id === parseInt(ev.teacherId))
  //       if (teacher.length <= 0) return
  //       let cours = coursesList.filter(
  //         (x) => parseInt(x.id) === teacher[0].competenceId,
  //       )
  //       if (cours.length <= 0) return
  //       // //console.log('teacher', teacher)
  //       // //console.log('teacher[0].competenceId', teacher[0].competenceId)
  //       // //console.log('cours', cours)
  //       // //console.log('coursesList', coursesList)
  //       return {
  //         // ...ev,
  //         classId: null,
  //         id: ev.id,
  //         teacherId: ev.teacherId,
  //         title:
  //           cours[0].name +
  //           '##' +
  //           teacher[0].firstName +
  //           ' ' +
  //           teacher[0].lastName,

  //         start: new Date(
  //           newDate.format('YYYY'),
  //           newDate.month(),
  //           newDate.date(),
  //           parseInt(startTime[0]),
  //           parseInt(startTime[1]),
  //           0,
  //         ),
  //         end: new Date(
  //           newDate.format('YYYY'),
  //           newDate.month(),
  //           newDate.date(),
  //           parseInt(endTime[0]),
  //           parseInt(endTime[1]),
  //           0,
  //         ),
  //         isDraggable: true,
  //         bgColor: cours[0].color,
  //       }
  //     })
  //     // //console.log('myEvents', myEvents)
  //     setMyEvents(adjEvents)
  //   }
  // }, [teacherList, coursesList])
  // {
  //     id: 12.5,
  //     title: 'Late Same Night Event',
  //     start: new Date(2015, 3, 17, 19, 30, 0),
  //     end: new Date(2015, 3, 17, 23, 30, 0),
  //   }

  // var today = new Date()
  // var dd = today.getDate()
  // var mm = today.getMonth() + 1 //January is 0!
  // var yyyy = today.getFullYear()

  // const setEvents = useCallback(
  //   (adjEvents) => {
  //     setMyEvents(adjEvents)
  //   },
  //   [setMyEvents],
  // )

  // const [myEvents, setMyEvents] = useState([])

  const [draggedEvent, setDraggedEvent] = useState();
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);

  // const [counters, setCounters] = useState({ item1: 0, item2: 0 })

  const eventPropGetter = useCallback(
    (event) => ({
      ...(event.isDraggable
        ? { className: "isDraggable" }
        : { className: "nonDraggable" }),
    }),
    []
  );
  //,
  const handleDragStart = useCallback((event) => setDraggedEvent(event), []);

  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

  const customOnDragOver = useCallback(
    (dragEvent) => {
      // check for undroppable is specific to this example
      // and not part of API. This just demonstrates that
      // onDragOver can optionally be passed to conditionally
      // allow draggable items to be dropped on cal, based on
      // whether event.preventDefault is called
      if (draggedEvent !== "undroppable") {
        //console.log('preventDefault')
        dragEvent.preventDefault();
      }
    },
    [draggedEvent]
  );

  const handleDisplayDragItemInCell = useCallback(
    () => setDisplayDragItemInCell((prev) => !prev),
    []
  );

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event;
      if (!allDay && droppedOnAllDaySlot) {
        event.allDay = true;
      }

      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end, allDay }];
      });
    },
    [setMyEvents]
  );

  const newEvent = useCallback(
    (event) => {
      setMyEvents((prev) => {
        //FIX error id
        const idList = prev.map((item) => item.id);
        let newId = 1;
        if (idList.length > 0) {
          newId = Math.max(...idList) + 1;
        }
        return [...prev, { ...event, id: newId }];
      });
    },
    [setMyEvents]
  );

  const onDropFromOutside = useCallback(
    ({ start, end, allDay: isAllDay }) => {
      if (draggedEvent === "undroppable") {
        setDraggedEvent(null);
        return;
      }

      const { bgColor, name, teacherId, classId } = draggedEvent;
      //console.log('draggedEvent', draggedEvent)
      const eventNew = {
        classId,
        teacherId,
        title: name,
        start,
        end,
        isAllDay: false,
        isDraggable: true,
        desc: "hello",
        bgColor: bgColor || "#ff7f50",
      };
      setDraggedEvent(null);

      //console.log('eventNew', eventNew)
      newEvent(eventNew);
    },
    [draggedEvent, teacherList, setDraggedEvent, newEvent]
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        //console.log('event', event)
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        //console.log('filtered', filtered)
        //console.log('filtered', filtered)
        return [...filtered, { ...existing, start, end }];
      });
    },
    [setMyEvents]
  );

  const deleteEvent = useCallback(
    (event) => {
      if (!event) return;
      setMyEvents((prev) => {
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return filtered;
      });
    },
    [setMyEvents]
  );

  const dayPropGetter = useCallback(
    (date) => ({
      ...(moment(date).day() === 2 && {
        className: "tuesday",
      }),
      ...(moment(date).day() === 0 && {
        style: {
          backgroundColor: "red",
          color: "white",
        },
      }),
    }),
    []
  );

  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = event.bgColor || "#ff7f50";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 0.8,
      color: "black",
      border: "1px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  const [culture, setCulture] = useState("ar-tn");
  const [rightToLeft, setRightToLeft] = useState(true);

  const { defaultDate, messages } = useMemo(
    () => ({
      //  defaultDate: new Date(2015, 3, 1),
      messages: lang[culture],
    }),
    [culture]
  );

  moment.locale("es");
  const localizer = momentLocalizer(moment);

  const cultureOnClick = useCallback(
    ({ target: { value } }) => {
      // really better to useReducer for simultaneously setting multiple state values
      setCulture(value);
      setRightToLeft(value === "ar-tn");
    },
    [setCulture]
  );
  const cultures = ["en", "en-GB", "es", "fr", "ar-tn"];
  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <div className="height600">
          <DragAndDropCalendar
            //   defaultDate={defaultDate}

            culture={culture}
            //defaultDate={defaultDate}

            messages={messages}
            rtl={rightToLeft}
            defaultView={Views.WEEK}
            views={[Views.WEEK, Views.DAY]}
            dragFromOutsideItem={
              displayDragItemInCell ? dragFromOutsideItem : null
            }
            draggableAccessor="isDraggable"
            //  eventPropGetter={eventPropGetter}
            events={myEvents}
            localizer={localizer}
            onDropFromOutside={onDropFromOutside}
            //    onDragOver={customOnDragOver}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            // onSelectSlot={newEvent}
            resizable
            selectable
            max={new Date("2022-07-01T18:00:00.000Z")}
            min={new Date("2022-07-20T07:00:00.000Z")}
            // toolbar={false}
            onSelectEvent={deleteEvent}
            dayPropGetter={dayPropGetter}
            eventPropGetter={eventStyleGetter}
            components={{
              event: CustomEvent,
            }}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="dndOutsideSourceExample">
          <select
            className="form-control"
            style={{ width: 200, display: "inline-block" }}
            defaultValue={"fr"}
            onChange={cultureOnClick}
          >
            {cultures.map((c, idx) => (
              <option key={idx} value={c}>
                {c}
              </option>
            ))}
          </select>
          <div className="inner">
            <h4>Courses List</h4>
            {loadTeacher ? (
              <div>Loading</div>
            ) : (
              <div style={{ overflow: "auto" }}>
                {teacherList.map((t) => (
                  <DragableItem
                    key={t.id}
                    handleDragStart={handleDragStart}
                    record={t}
                    classId=""
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
// DnDOutsideResource.propTypes = {
//   localizer: PropTypes.instanceOf(DateLocalizer),
// }
export default CalenderCreate;
