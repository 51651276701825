import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Create, ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useGetIdentity,
  usePermissions,
  useTranslate
} from 'react-admin';
import { Parent } from '../types';

const ReviewCreate = () => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const [defultFilter, setdefultFilter] = useState({ roles: ["PARENT", "TEACHER"] });
  useEffect(() => {
    if (!permissions) return;
    if (!identity) return;
    if (permissions.role === 'PARENT') {
      setdefultFilter({ roles: ["TEACHER", "ADMIN"] });

    } else if (permissions.role === 'TEACHER') {
      setdefultFilter({ roles: ["PARENT", "ADMIN"] });

    }
  }, [identity, permissions]);


  const validateForm = (
    values: Record<string, any>,
  ): Record<string, any> => {
    const errors = {} as any;
    if (!values.toId) {
      errors.toId = 'ra.validation.required';
    }
    if (!values.body) {
      errors.body = 'ra.validation.required';
    }


    return errors;
  };

  const transformData = async (data) => {
    //console.log(data);
    return { ...data, fromId: identity.id };
  };
  const CreateToolbar = () => (
    <Toolbar>
      <SaveButton
        type="button"
        alwaysEnable
        label="Save"
        transform={transformData}
      />
    </Toolbar>
  );

  return (
    <Create >
      <Box pt={5} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            {translate('resources.smss.detail')}
          </Typography>

        </Stack>
        <SimpleForm sx={{ pt: 0, pb: 0 }} validate={validateForm} toolbar={<CreateToolbar />}>
          <Grid container rowSpacing={1} mb={1}>
            <Grid item xs={5}>
              <ReferenceInput source="toId" label="to" reference="users" filter={defultFilter}>
                <SelectInput
                  label="to"
                  source="toId"
                  optionText={(choice?: Parent) =>
                    choice?.id // the empty choice is { id: '' }
                      ? `${choice.firstName} ${choice.lastName}`
                      : ''
                  }
                />
              </ReferenceInput>
            </Grid>
          </Grid>



          <TextInput source="body" maxRows={15} multiline fullWidth />
        </SimpleForm>
      </Box>
    </Create >
  );
};

export default ReviewCreate;
