import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import { Fragment, useState } from "react";
import {
  DeleteButton,
  EditBase,
  EditProps,
  SaveButton,
  SimpleForm,
  TextInput,
  ToolbarProps,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from "react-admin";

import { Courses } from "../types";

import ColorPicker from "./ColorPicker";
import SectionTitle from "./SectionTitle";
interface Props extends EditProps<Courses> {
  onCancel: () => void;
}

const CoursesEdit = ({ onCancel, ...props }: Props) => {
  const translate = useTranslate();

  // if (record) setColor(record.color)

  const [Color, setColor] = useState();

  const transformData = (data) => {
    //console.log('data', data)
    return {
      id: data.id,
      data: {
        name: data.name,
        color: Color,
      },
    };
  };

  const CoursesEditToolbar = (props: ToolbarProps<Courses>) => {
    const { resource } = props;
    const redirect = useRedirect();
    const notify = useNotify();

    const record = useRecordContext<Courses>(props);

    if (!record) return null;
    return (
      <Toolbar
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          justifyContent: "space-between",
          minHeight: { sm: 0 },
        }}
      >
        <Fragment>
          <SaveButton
            alwaysEnable
            mutationOptions={{
              onSuccess: () => {
                notify("ra.notification.updated", {
                  type: "info",
                  messageArgs: { smart_count: 1 },
                  undoable: true,
                });
                redirect("list", "courses");
              },
            }}
            type="button"
            transform={transformData}
          />
          <DeleteButton record={record} resource={resource} />
        </Fragment>
      </Toolbar>
    );
  };

  return (
    <EditBase {...props}>
      <Box pt={5} width={{ xs: "100vW", sm: 400 }} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            {/* {translate('resources.reviews.detail')} */}
            Courses Detail
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<CoursesEditToolbar />}>
          <SectionTitle label="resources.courses.fields.name" />
          <TextInput source="name" fullWidth />
          <SectionTitle label="resources.courses.fields.color" />
          <ColorPicker Color={Color} setColor={setColor} />
        </SimpleForm>
      </Box>
    </EditBase>
  );
};

export default CoursesEdit;
