import { usePermissions } from 'react-admin';
import FileList from './FileList';
import ValidationFiles from './validation/ReviewFileListDesktop';

const FileListRoot = () => {
  const { isLoading, permissions } = usePermissions();
  
  return isLoading ?(  <div>Loading</div>):( <>
    
    {permissions.role==="ADMIN" && <ValidationFiles/>}
    {permissions.role!=="ADMIN" && <FileList/>}
   </>
  )
}

export default FileListRoot