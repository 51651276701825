// @ts-nocheck
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  CreateButton,
  FilterButton,
  FilterContext,
  FilterForm,
  ListBase,
  Pagination,
  SearchInput,
  Title,
  TopToolbar,
  useAuthProvider,
  useDataProvider,
  useGetResourceLabel
} from 'react-admin';

import Aside from './Aside';
import ImageList from './GridList';

const FileList = () => {
  const getResourceLabel = useGetResourceLabel();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();

  // const { identity, isLoading: identityLoading } = useGetIdentity()
  // const { isLoading: permissionsLaoding, permissions } = usePermissions()
  const [filter, setfilter] = useState({});

  const [identity, setIdentity] = useState();
  const [permission, setPermission] = useState();

  useEffect(() => {
    authProvider.getIdentity().then((data) => {
      setIdentity(data);
    });

    authProvider.getPermissions().then((data) => {
      setPermission(data);
    });
  }, [authProvider]);

  useEffect(async () => {
    if (!identity) return;
    if (!permission) return;
    let flt = {};
    if (permission.role === 'ADMIN') {
      setfilter({});
      return;
    }

    flt = { userId: identity.id, fileStatus: 'ACCEPTED' };
    if (permission.role === 'STUDENT') {

      let classUser = [];
      await dataProvider
        .getList('classes', {
          filter: { userId: identity.id },
        })
        .then((data) => {
          classUser = data.data;
        });


      if (classUser.length === 1) {
        flt = { ...flt, classId: classUser[0].id };
      }
    }

    setfilter(flt);
  }, [dataProvider, identity, permission]);
  if (!permission) return null;
  const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
      <FilterButton />
      {(permission.role === 'ADMIN' || permission.role === 'TEACHER') && <CreateButton />}
    </TopToolbar>
  );

  return (
    <ListBase
      perPage={24}
      sort={{ field: 'reference', order: 'ASC' }}
      filter={filter}


    >
      <Title defaultTitle={getResourceLabel('files', 2)} />
      <FilterContext.Provider value={FileFilters}>
        <ListActions isSmall={isSmall} />
        {isSmall && (
          <Box m={1}>
            <FilterForm />
          </Box>
        )}
      </FilterContext.Provider>
      <Box display="flex">
        <Aside />
        <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
          <ImageList />
          <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
        </Box>
      </Box>
    </ListBase>
  );
};
export const FileFilters = [<SearchInput source="q" alwaysOn />];

export default FileList;
