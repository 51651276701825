//@ts-nocheck
import { Box, Drawer, Theme, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { CreateButton, FilterButton, List, TopToolbar, useDataProvider, useGetIdentity, usePermissions } from 'react-admin';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import ReviewEdit from './ReviewEdit';
import { reviewFiltersFull, reviewFiltersLight } from './reviewFilters';
import ReviewListDesktopAdmin from './ReviewListDesktopAdmin';

import ReviewListDesktopParent from './ReviewListDesktopParent';
import ReviewListMobile from './ReviewListMobile';
import ReviewShow from './ReviewShow';

const ReviewList = () => {
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate('/smss');
  }, [navigate]);

  const match = matchPath('/smss/:id', location.pathname);
  const match2 = matchPath('/smss/:id/show', location.pathname);

  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const [usersId, setUsersId] = useState([]);
  const [defultFilter, setdefultFilter] = useState({});
  const [reviewFilters, setreviewFilters] = useState(reviewFiltersFull);




  useEffect(async () => {
    if (!permissions) return;
    if (!identity) return;
    if (permissions.role === 'PARENT' || permissions.role === 'TEACHER') {
      setdefultFilter({ userId: identity.id, smsStatus: 'ACCEPTED' });



      setreviewFilters(reviewFiltersLight);
    }
  }, [identity, permissions]);

  if (!permissions) return null;

  const ReviewListDesktop = ({ selectedRow, selectedRow2 }) => {

    const { permissions } = usePermissions();
    if (!permissions) return null;
    return (
      <div>
        {(permissions.role === 'ADMIN' || permissions.role === 'TEACHER') && <ReviewListDesktopAdmin selectedRow={selectedRow} />}
        {permissions.role === 'PARENT' && <ReviewListDesktopParent selectedRow={selectedRow2} />}

      </div>

    );
  };

  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
      <CreateButton />

    </TopToolbar>
  );


  return (
    <Box display="flex">
      <List
        actions={<ListActions />}
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: (!!match || !!match2) ? '400px' : 0,
        }}
        filterDefaultValues={defultFilter}
        filters={reviewFilters}
        perPage={25}
        sort={{ field: 'date', order: 'DESC' }}
      >
        {isXSmall ? (
          <ReviewListMobile />
        ) : (
          <ReviewListDesktop
            selectedRow={
              !!match ? parseInt((match as any).params.id, 10) : undefined
            }
            selectedRow2={
              !!match2 ? parseInt((match2 as any).params.id, 10) : undefined
            }
          />
        )}
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {permissions.role === 'ADMIN' && !!match && (
          <ReviewEdit id={(match as any).params.id} onCancel={handleClose} />
        )}
        {(permissions.role === 'PARENT' || permissions.role === 'TEACHER') && !!match && (
          <ReviewShow id={(match as any).params.id} onCancel={handleClose} />
        )}
      </Drawer>
    </Box>
  );
};

export default ReviewList;
