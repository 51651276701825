import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  FilledInput,
  IconButton,
  InputAdornment,
} from '@mui/material'
import React, { useState } from 'react'
const PasswordInputCustom = ({ password, setPassword }) => {
  const [showPassword, setshowPassword] = useState(false)

  const handleChange = (prop) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  }

  const makePassword = (length = 8) => {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  return (
    <Box display={{ xs: 'block', sm: 'flex' }}>
      <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
        <FilledInput
          id="filled-adornment-password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          fullWidth
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>
      <Box flex={1} mt={{ xs: 0, sm: '1em' }}>
        <Button variant="contained" onClick={() => setPassword(makePassword())}>
          Change
        </Button>
      </Box>
    </Box>
  )
}

export default PasswordInputCustom
