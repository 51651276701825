import { Datagrid, useGetList, TextField } from "react-admin";
import FullNameField from "./FullNameField";


const ParentDashboard = ({ identity }) => {
  if (!identity) return null




  const { data, total, isLoading } = useGetList('students', {
    pagination: { page: 1, perPage: 10 },
    filter: { parentId: identity.id }
  });
  if (isLoading) return null
  return (
    <Datagrid
      data={data}
      total={total}
      isLoading={isLoading}
      sort={{ field: 'last_seen', order: 'DESC' }}
      bulkActionButtons={false}
    >

      <FullNameField />
      <TextField source="age" />
    </Datagrid>
  );
};

export default ParentDashboard;
