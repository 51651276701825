// @ts-nocheck
import { Theme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateInput,
  FilterButton,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TopToolbar,
  useDataProvider,
  usePermissions,
} from "react-admin";
import { Class } from "../types";

//import MobileGrid from './MobileGrid'

const ClassPresenceFilters = [
  <DateInput source="dateGte" />,
  <DateInput source="dateLte" />,
  <ReferenceInput
    source="classId"
    reference="classes"
    label="Classes"
    filter={{ activeSchoolYear: true }}
  >
    <SelectInput
      //source="schoolyearId"
      optionText={(choice?: Class) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.name}`
          : ""
      }
    />
  </ReferenceInput>,
  //<SchoolYearInput source="schoolYear" />,
];

const ClassPresenceList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
  const dataProvider = useDataProvider();
  const [filter, setfilter] = useState({});
  const { permissions } = usePermissions();
  const [action, setaction] = useState("edit");
  const [bulk, setBulk] = useState(true);
  useEffect(async () => {
    if (!permissions) return null;
    if (permissions.role === "PARENT") {
      setaction("show");
    }
    if (permissions.role === "PARENT" || permissions.role === "TEACHER") {
      setBulk(false);
    }
    const setupFilter = async () => {
      let res = [];
      return await dataProvider
        .getList("classes", {
          filter: { activeSchoolYear: true },
        })
        .then(({ data }) => {
          //console.log('data', data);
          return data.map((c) => c.id);
        });
    };

    const filterR = await setupFilter();
    //console.log('userClass', filterR);
    setfilter({ classIds: filterR });
  }, [permissions]);

  if (!permissions) return null;
  const ListActions = () => {
    // const { permissions } = usePermissions();
    if (!permissions) return null;
    return (
      <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        <FilterButton />
        {(permissions.role === "ADMIN" || permissions.role === "TEACHER") && (
          <CreateButton />
        )}
      </TopToolbar>
    );
  };
  return (
    <List
      actions={<ListActions />}
      filters={ClassPresenceFilters}
      //sort={{ field: 'last_seen', order: 'DESC' }}
      perPage={25}
      filter={filter}
    >
      {isXsmall ? (
        ///    <MobileGrid />
        <div />
      ) : (
        <Datagrid
          bulkActionButtons={bulk}
          rowClick={action}
          optimized
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <ReferenceField
            source="classId"
            reference="classes"
            link={false}
            label="Classes"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="createdAt" label="Created At" />
        </Datagrid>
      )}
    </List>
  );
};

export default ClassPresenceList;
