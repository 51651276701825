import { useEffect } from 'react'
import { useRecordContext } from 'react-admin'
import { DateRange } from 'react-date-range'
import { SchoolYear } from '../types'
const DateRangeEdit = ({ valueDate, setValueDate }) => {
  const record = useRecordContext<SchoolYear>()
  useEffect(() => {
    if (record)
      setValueDate({
        startDate: new Date(record.startDate),
        endDate: new Date(record.endDate),
        key: 'selection',
      })
  }, [record])

  const handleSelect = (date) => {
    setValueDate(date.selection)
  }
  return (
    <DateRange
      onChange={handleSelect}
      ranges={[valueDate]}
      editableDateInputs={true}
      moveRangeOnFirstSelection={false}
    />
  )
}

export default DateRangeEdit
