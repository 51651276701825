import {
  Datagrid,
  DateField,
  Identifier,
  TextField
} from 'react-admin';
import CustomerReferenceField from './CustomerReferenceField';
import rowStyle from './rowStyle';

export interface ReviewListDesktopProps {
  selectedRow?: Identifier;
}


const ReviewListDesktopParent = ({ selectedRow }: ReviewListDesktopProps) => {

  //console.log(selectedRow);
  return (
    <Datagrid
      rowClick="edit"
      rowStyle={rowStyle(selectedRow)}
      optimized
      bulkActionButtons={false}
      sx={{
        '& .RaDatagrid-thead': {
          borderLeftColor: 'transparent',
          borderLeftWidth: 5,
          borderLeftStyle: 'solid',
        },
        '& .column-body': {
          maxWidth: '18em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <CustomerReferenceField source="fromId" link={false} />
      <CustomerReferenceField source="toId" link={false} />

      <DateField source="createdAt" />

      <TextField source="body" />

    </Datagrid>
  );
};

export default ReviewListDesktopParent;
