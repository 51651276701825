import axios from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { AuthProvider } from "react-admin";

const authProvider: AuthProvider = {
  login: async ({ email, password }) => {
    var graphql = JSON.stringify({
      query: `mutation login{signIn(input:{username:"${email}",password:"${password}"})}`,
      variables: {},
    });
    try {
      const response = await axios({
        url: "https://api" + window.location.host + "/",
        //TODO delete me
        // url: "http://localhost:9000/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        data: graphql,
      });
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const auth = response.data;
      localStorage.setItem("auth", JSON.stringify(auth.data.signIn));
    } catch {
      throw new Error("Network error");
    }
  },

  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("auth");
      return Promise.reject({ redirectTo: "/login" });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login" }),
  getPermissions: () => {
    try {
      const { role } = jwtDecode<JwtPayloadCustom>(
        localStorage.getItem("auth") || ""
      );
      return Promise.resolve({
        role,
      });
    } catch (error) {
      return Promise.reject(error);
    }
    // Promise.reject('Unknown method')
  },
  getIdentity: () => {
    try {
      const { id, sub, avatar } = jwtDecode<JwtPayloadCustom>(
        localStorage.getItem("auth") || ""
      );
      // console.log(
      //   'getIdentity',
      //   jwtDecode<JwtPayloadCustom>(localStorage.getItem('auth') || ''),
      // )
      // return Promise.resolve({ id, fullName, avatar });
      // console.log(id, sub, avatar)
      return Promise.resolve({
        id,
        fullName: sub,
        avatar,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default authProvider;
type JwtPayloadCustom = JwtPayload & {
  // interface JwtPayloadCustom {
  id: string;
  fullName: string;
  avatar: string;
  role: string;
};
