export const darkTheme = {
  palette: {
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#FBBA72',
    },
    mode: 'dark' as 'dark', // Switching the dark mode on is a single property value change.
  },
  sidebar: {
    width: 200,
  },
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #000',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #90caf9',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffffb3',
          backgroundColor: '#616161e6',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // disable ripple for perf reasons
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover:active::after': {
            // recreate a static ripple color
            // use the currentColor to make it work both for outlined and contained buttons
            // but to dim the background without dimming the text,
            // put another element on top with a limited opacity
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'currentColor',
            opacity: 0.3,
            borderRadius: 'inherit',
          },
        },
      },
    },
  },
}

export const lightTheme = {
  palette: {
    primary: {
      main: '#4f3cc9',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    mode: 'light' as 'light',
  },

  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 200,
  },
  'img.RaImageField-image': { width: '100px', height: '100px' },
  components: {
    RaFileInput: {
      styleOverrides: {
        // root: {
        '&.RaImageField-image': { width: '100px', height: '100px' },
        // },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: '3px solid #fff',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #4f3cc9',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // disable ripple for perf reasons
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover:active::after': {
            // recreate a static ripple color
            // use the currentColor to make it work both for outlined and contained buttons
            // but to dim the background without dimming the text,
            // put another element on top with a limited opacity
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'currentColor',
            opacity: 0.3,
            borderRadius: 'inherit',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#808080',
          backgroundColor: '#fff',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&$disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
  },
}
type Direction = 'ltr' | 'rtl'
let dis: Direction = 'ltr'
export const lightThemertl = { ...lightTheme, direction: dis }
