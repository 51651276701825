// @ts-nocheck
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Calendar as BigCalendar,
    momentLocalizer,
    Views
} from 'react-big-calendar';
// Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'
// Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
//import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
//import './styles.css'
import lang from './globalize/lang';
// require('globalize/lib/cultures/globalize.culture.en-GB')
// require('globalize/lib/cultures/globalize.culture.es')
// require('globalize/lib/cultures/globalize.culture.fr')
// require('globalize/lib/cultures/globalize.culture.ar-TN')
//import 'globalize/lib/cultures/globalize.culture.ar-TN'

import 'moment/locale/ar-tn';
import 'moment/locale/fr';
import { useDataProvider, useGetIdentity, useGetList, useRecordContext } from 'react-admin';
import CustomEvent from './customEvent ';





const CalenderView = () => {
    const [calendarData, setCalendarData] = useState([]);
    const [myEvents, setMyEvents] = useState([]);
    const [classUser, setClassUser] = useState(null);
    const record = useRecordContext();
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const dataProvider = useDataProvider();
    let adjEvents = [];
    // if (identityLoading) return null


    // const currentClassId = 1;
    // const { data: clasesList, isLoading: clasesLoading } = useGetList('classes', {
    //     activeSchoolYear: true,
    //     studentId: identity.id | 1,
    // });

    // if (clasesLoading) return null
    // // console.log('clasesList', clasesList)
    // const { data: calendarData, total, isLoading, error } = useGetList(
    //     'calendars',
    //     {
    //         // filter: { classId: record.id },
    //         filter: { classId: clasesList[0].id },
    //     },
    // );


    const {
        data: teacherList,
        isLoading: loadTeacher,
        error: errorTeacher,
    } = useGetList('teachers', {
        // filter: { classId: record.id },
    });

    const { data: coursesList, isLoading: loadCours } = useGetList('courses', {
        //  id: record.id,
    });

    useEffect(async () => {
        if (!identity) return;

        // let classUser = [];
        if (classUser !== null) return
        // if (classUser.length >= 1) return
        await dataProvider
            .getList('classes', {
                filter: {
                    activeSchoolYear: true,
                    userId: identity.id,
                },
            })
            .then((data) => setClassUser(data.data));


        // if (classUser.length < 1) return


        // await dataProvider
        //     .getList('classes', {
        //         filter: {
        //             classId: classUser[0].id
        //         },
        //     })
        //     .then((data) => {
        //         setCalendarData(data.data);
        //     });

    }, [dataProvider, identity, classUser]);



    useEffect(async () => {

        // let classUser = [];
        if (classUser === null) return
        console.log('classUser[0].id', classUser[0].id)
        await dataProvider
            .getList('calendars', {
                filter: {
                    classId: classUser[0].id
                },
            })
            .then((data) => {
                console.log('CalendarData', data.data)
                setCalendarData(data.data);
            });

    }, [dataProvider, classUser]);



    useEffect(() => {
        let currentWeekDay = moment().isoWeekday();
        // if (typeof clasesList === 'undefined' || clasesList.length === 0) return
        if (calendarData && calendarData.length > 0) {
            adjEvents = calendarData.map((ev, ind) => {
                // const newDate = today.setDate(
                //   today.getDate() + (ev.weekDay - currentWeekDay),
                // )
                const newDate = moment().add(ev.weekDay - currentWeekDay, 'd');
                // console.log('newDate', newDate)
                let startTime = ev.startTime.trim().split(':');
                let endTime = ev.endTime.trim().split(':');

                if (
                    typeof teacherList === 'undefined' ||
                    typeof coursesList === 'undefined'
                )
                    return;

                let teacher = teacherList.filter((x) => x.id === parseInt(ev.teacherId));
                if (teacher.length <= 0) return;
                let cours = coursesList.filter(
                    (x) => parseInt(x.id) === teacher[0].competenceId,
                );
                if (cours.length <= 0) return;

                return {

                    classId: classUser[0].id,
                    id: ev.id,
                    teacherId: ev.teacherId,
                    title:
                        cours[0].name +
                        '##' +
                        teacher[0].firstName +
                        ' ' +
                        teacher[0].lastName,

                    start: new Date(
                        newDate.format('YYYY'),
                        newDate.month(),
                        newDate.date(),
                        parseInt(startTime[0]),
                        parseInt(startTime[1]),
                        0,
                    ),
                    end: new Date(
                        newDate.format('YYYY'),
                        newDate.month(),
                        newDate.date(),
                        parseInt(endTime[0]),
                        parseInt(endTime[1]),
                        0,
                    ),
                    isDraggable: true,
                    bgColor: cours[0].color,
                };
            });
            // console.log('myEvents', myEvents)
            setMyEvents(adjEvents);
        }
    }, [calendarData, teacherList, coursesList]);












    const dayPropGetter = useCallback(
        (date) => ({
            ...(moment(date).day() === 2 && {
                className: 'tuesday',
            }),
            ...(moment(date).day() === 0 && {
                style: {
                    backgroundColor: 'red',
                    color: 'white',
                },
            }),
        }),
        [],
    );

    const eventStyleGetter = (event, start, end, isSelected) => {
        var backgroundColor = event.bgColor || '#ff7f50';
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'black',
            border: '1px',
            display: 'block',
        };
        return {
            style: style,
        };
    };

    const [culture, setCulture] = useState('ar-tn');
    const [rightToLeft, setRightToLeft] = useState(true);

    const { defaultDate, messages } = useMemo(
        () => ({
            //  defaultDate: new Date(2015, 3, 1),
            messages: lang[culture],
        }),
        [culture],
    );

    moment.locale('es');
    const localizer = momentLocalizer(moment);


    const cultures = ['en', 'en-GB', 'es', 'fr', 'ar-tn'];
    return (

        <div className="height600">
            <BigCalendar

                culture={culture}

                messages={messages}
                rtl={rightToLeft}
                defaultView={Views.WEEK}
                views={[Views.WEEK, Views.DAY]}

                events={myEvents}
                localizer={localizer}

                max={new Date('2022-07-01T18:00:00.000Z')}
                min={new Date('2022-07-20T07:00:00.000Z')}

                dayPropGetter={dayPropGetter}
                eventPropGetter={eventStyleGetter}
                components={{
                    event: CustomEvent,
                }}
            />
        </div>

    );
};

export default CalenderView;
