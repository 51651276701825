// @ts-nocheck
import { memo, useEffect, useState } from 'react'
import { useDataProvider, useRecordContext } from 'react-admin'
import { Student } from '../types'

const FullNameField = () => {
  const record = useRecordContext<Student>()

  return (
    <>
      {record.firstName} {record.lastName}
    </>
  )
}

export default memo<Props>(FullNameField)



