import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import {
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from "react-admin";
import PasswordInputCustom from "../commun/PasswordInputCustom";
import SegmentsInput from "../commun/SegmentsInput";
import Aside from "./Aside";
import FullNameField from "./FullNameField";
import { validateForm } from "./VisitorCreate";
function formatLogo(value) {
  // console.log('formatLogo', value)
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

const convertFileToBase64 = (file) =>
  new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const req = [required()];

const VisitorEdit = () => {
  const translate = useTranslate();
  const [password, setPassword] = useState("");
  const transformUser = async (data) => {
    //  console.log('submit', data)
    let avatarObj = data.avatar;
    let image: string | ArrayBuffer = data.avatar;
    if (
      (typeof avatarObj === "object" || typeof avatarObj === "function") &&
      avatarObj !== null
    ) {
      // Path to the image
      image = await convertFileToBase64(avatarObj).then((imgB64) => {
        return imgB64;
      });
    }
    // console.log('image', image)
    let result = {
      address: data.address,
      avatar: image,
      birthDate: data.birthDate,
      fatherId: data.fatherId === "" ? undefined : data.fatherId,
      firstName: data.firstName,
      lastName: data.lastName,
      motherId: data.motherId === "" ? undefined : data.motherId,
      phoneNumber: data.phoneNumber,
      postalCode: data.postalCode,
      role: data.role,
      state: data.state,
      username: data.username,
    };

    if (typeof password !== "undefined" && password.trim() !== "") {
      return { ...result, password };
    }
    return result;
  };

  const PostCreateToolbar = () => (
    <Toolbar>
      <SaveButton
        type="button"
        alwaysEnable
        label="Save"
        transform={transformUser}
      />
    </Toolbar>
  );

  return (
    <Edit title={<VisitorTitle />} aside={<Aside />}>
      <SimpleForm validate={validateForm} toolbar={<PostCreateToolbar />}>
        <div>
          <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.customers.fieldGroups.identity")}
              </Typography>
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="firstName" isRequired fullWidth />
                </Box>
                <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="lastName" isRequired fullWidth />
                </Box>
              </Box>
              <TextInput type="username" source="username" fullWidth />
              <Box display={{ xs: "block", sm: "flex" }}>
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <DateInput source="birthDate" fullWidth helperText={false} />
                </Box>
                {/* <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} /> */}
                <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput
                    source="phoneNumber"
                    fullWidth
                    helperText={false}
                  />
                </Box>
              </Box>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                {translate("resources.customers.fieldGroups.address")}
              </Typography>
              <TextInput
                source="address"
                multiline
                fullWidth
                helperText={false}
              />
              <Box display={{ xs: "block", sm: "flex" }}>
                {/* <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        source="city"
                                        fullWidth
                                        helperText={false}
                                    />
                                </Box> */}
                <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                  <TextInput source="state" fullWidth helperText={false} />
                </Box>
                <Box flex={2}>
                  <TextInput source="postalCode" fullWidth helperText={false} />
                </Box>
              </Box>

              <Box mt="1em" />

              <Typography variant="h6" gutterBottom>
                {translate("resources.customers.fieldGroups.change_password")}
              </Typography>
              <PasswordInputCustom
                password={password}
                setPassword={setPassword}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.customers.fieldGroups.stats")}
              </Typography>

              <SegmentsInput fullWidth source="fatherId" />

              <SegmentsInput fullWidth source="motherId" />

              <ImageInput
                format={formatLogo}
                source="avatar"
                label="Related pictures"
                accept="image/*"
                multiple={false}
              >
                <ImageField
                  source="url"
                  title="title"
                  sx={{
                    "& .RaImageField-image": {
                      width: "250px",
                    },
                  }}
                />
              </ImageInput>
            </Grid>
          </Grid>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const VisitorTitle = () => <FullNameField size="32" sx={{ margin: "5px 0" }} />;

export default VisitorEdit;
