import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { DateField, useRecordContext, useTranslate } from 'react-admin'
import { Student } from '../types'

const Aside = () => {
  const record = useRecordContext<Student>()
  return (
    <Box width={400} display={{ xs: 'none', lg: 'block' }}>
      {record && <EventList />}
    </Box>
  )
}

const EventList = () => {
  const record = useRecordContext<Student>()
  const translate = useTranslate()

  return (
    <Box ml={2}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate('resources.customers.fieldGroups.history')}
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">
                  {translate('resources.customers.fields.first_seen')}
                </Typography>
                <DateField record={record} source="createdAt" />
              </Box>
            </Grid>
            <Grid item xs={6} display="flex" gap={1}>
              <AccessTimeIcon fontSize="small" color="disabled" />
              <Box flexGrow={1}>
                <Typography variant="body2">
                  {translate('resources.customers.fields.last_seen')}
                </Typography>
                <DateField record={record} source="lastSeen" />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Aside
