import { Typography } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import {
  BooleanInput,
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css'
import SectionTitle from './SectionTitle'

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }

  return errors
}
const YearCreate = () => {
  const [value, setValue] = useState('')
  const [valueDate, setValueDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  const handleSelect = (date) => {
    setValueDate(date.selection)
    //console.log(date) // native Date object
  }
  const transformData = (data) => {

    return {

      active: data.active,
      name: data.name,
      startDate: moment(valueDate.startDate).format('YYYY-MM-DD'),
      endDate: moment(valueDate.endDate).format('YYYY-MM-DD'),

    }

  }
  const ClassesCreateToolbar = () => {
    const redirect = useRedirect()
    const notify = useNotify()

    return (
      <Toolbar>
        <SaveButton type="button" variant="text" transform={transformData} />
        <SaveButton
          label="pos.action.save_and_add"
          mutationOptions={{
            onSuccess: (data) => {
              notify('ra.notification.created', {
                type: 'info',
                messageArgs: { smart_count: 1 },
              })

              setValueDate({
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
              })
              redirect(false)
            },
          }}
          type="button"
          variant="text"
          transform={transformData}
        />
      </Toolbar>
    )
  }

  return (
    <Create redirect="show">
      <SimpleForm
        toolbar={<ClassesCreateToolbar />}
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          name: '',
        }}
        validate={validateForm}
      >
        <SectionTitle label="resources.year.fields.name" />
        <TextInput source="name" isRequired fullWidth />
        <BooleanInput label="Active" source="active" />
        <Typography variant="h6" gutterBottom>
          Date
        </Typography>
        <DateRange
          onChange={handleSelect}
          ranges={[valueDate]}
          editableDateInputs={true}
          moveRangeOnFirstSelection={false}
        />
      </SimpleForm>
    </Create>
  )
}

export default YearCreate
