import * as React from 'react';
import { FieldProps, Link, useRecordContext } from 'react-admin';
import { User } from '../types';
import FullNameField from './FullNameField';


const CustomerLinkField = (props: FieldProps<User>) => {
    const record = useRecordContext<User>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/users/${record.id}`}>
            <FullNameField />
        </Link>
    );
};

CustomerLinkField.defaultProps = {
    source: 'id',
};

export default CustomerLinkField;
