import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";
import { Theme, useMediaQuery } from "@mui/material";
import { startOfDay, subDays } from "date-fns";
import { CSSProperties, useMemo } from "react";
import { useGetList } from "react-admin";
import { Sms } from "../types";
import NbCard from "./NbCard";
import PendingOrders from "./PendingOrders";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Spacer = () => <span style={{ width: "1em" }} />;
const VerticalSpacer = () => <span style={{ height: "1em" }} />;

const AdminDashboard = () => {
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);
  const aWeekAgo = useMemo(() => subDays(startOfDay(new Date()), 7), []);

  const { data: smss } = useGetList<Sms>("smss", {
    // filter: { sms_status: aMonthAgo.toISOString() },
    sort: { field: "date", order: "DESC" },
    pagination: { page: 1, perPage: 50 },
  });

  const aggregationSms = useMemo(() => {
    if (!smss)
      return { nbPending: 0, nbAccepted: 0, pendingSms: [], AcceptedSms: [] };
    const aggregations = smss
      // .filter((sms) => sms.status !== 'PENDING')
      .reduce(
        (stats, sms) => {
          if (sms.status === "PENDING") {
            stats.nbPending++;
            stats.pendingSms.push(sms);
          }
          if (sms.status === "ACCEPTED") {
            stats.nbAccepted++;
            stats.AcceptedSms.push(sms);
          }
          return stats;
        },
        {
          nbPending: 0,
          nbAccepted: 0,
          pendingSms: [],
          AcceptedSms: [],
        }
      );
    return {
      nbPending: aggregations.nbPending,
      nbAccepted: aggregations.nbAccepted,
      pendingSms: aggregations.pendingSms,
      AcceptedSms: aggregations.AcceptedSms,
    };
  }, [smss]);

  const { nbPending, nbAccepted, pendingSms } = aggregationSms;

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <PendingOrders orders={pendingSms} />
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}></div>
  ) : (
    <>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <NbCard
              title="resources.smss.state.sent"
              link="/smss"
              value={nbAccepted}
              icon={PhoneForwardedIcon}
              iconColor="#0b7a4c"
            />
            <Spacer />
            <NbCard
              title="resources.smss.state.pending"
              link="/smss"
              value={nbPending}
              icon={PhonePausedIcon}
              iconColor="#af760b"
            />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>{/* <AbsenceList /> */}</div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
