import { SxProps, Typography } from '@mui/material'
import { memo } from 'react'
import { FieldProps, useRecordContext } from 'react-admin'
import { Teacher, User } from '../types'
import AvatarField from './AvatarField'

interface Props extends FieldProps<User> {
  size?: string
  sx?: SxProps
}

const FullNameField = (props: Props) => {
  const { size } = props
  const record = useRecordContext<Teacher>()
  return record ? (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={props.sx}
    >
      <AvatarField
        record={record}
        size={size}
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      />
      {record.firstName} {record.lastName}
    </Typography>
  ) : null
}

FullNameField.defaultProps = {
  source: 'fullName',
  label: 'resources.customers.fields.name',
}

export default memo<Props>(FullNameField)
