import { SxProps, Typography } from '@mui/material'
import { memo } from 'react'
import { FieldProps } from 'react-admin'
import { User } from '../types'
import AvatarField from './AvatarField'

interface Props extends FieldProps<User> {
  size?: string
  sx?: SxProps
  avatar?: string
  fullName: string
}

const FullNameField = (props: Props) => {
  const { avatar, fullName, size } = props

  return (
    <Typography
      variant="body2"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
      sx={props.sx}
    >
      <AvatarField
        avatar={avatar}
        size={size}
        sx={{
          mr: 1,
          mt: -0.5,
          mb: -0.5,
        }}
      />
      {fullName}
    </Typography>
  )
}

export default memo<Props>(FullNameField)
