// @ts-nocheck
import { TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import FullNameField from './FullNameField'
import SelecInputClassList from './SelecInputClassList'

type itemDef = {
  id: string
  fullName: string
  avatar: string
  age: number
  classId?: string | number
}
type coDef = {
  name: string
  items: itemDef[]
}

type col = {
  source: coDef
  dest: coDef
}

const onDragEnd = (result, columns, setColumns, setOrgColumns) => {
  if (!result.destination) return
  const { source, destination } = result

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId]
    const destColumn = columns[destination.droppableId]
    const sourceItems = [...sourceColumn.items]
    const destItems = [...destColumn.items]
    const [removed] = sourceItems.splice(source.index, 1)
    destItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    })
    setOrgColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    })
  } else {
    const column = columns[source.droppableId]
    const copiedItems = [...column.items]
    const [removed] = copiedItems.splice(source.index, 1)
    copiedItems.splice(destination.index, 0, removed)
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
    setOrgColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    })
  }
}
const classMemberCreate: React.FC<{ columns: col; setColumns: any }> = ({
  columns,
  setColumns,
}) => {
  const dataProvider = useDataProvider()

  const [classId, setclassId] = useState(-1)
  const [inputfilter, setInputfilter] = useState('')
  const [org, setOrgColumns] = useState(columns)
  useEffect(async () => {

    let classmembersList = []
    if (classId > 0) {
      await dataProvider
        .getList('classMembers', { filter: { classId: classId } })
        .then((data) => data.data)
        .then(async (classmembers) => {
          return await dataProvider
            .getMany('students', {
              filter: { ids: classmembers.map((cm) => cm.studentId) },
            })
            .then((data) => data.data)
            .then((studentList) => {
              //console.log('classmembers', classmembers)
              return classmembers.map((c) => {
                let st = studentList.filter(
                  (s) => String(s.id) === String(c.studentId),
                )
                //console.log('st', st)
                if (st.length > 0)
                  classmembersList.push({
                    id: String(st[0].id),
                    fullName: st[0].fullName,
                    avatar: st[0].avatar,
                    age: st[0].age,
                  })
              })
            })
        })
    } else {
      classmembersList = await dataProvider
        .getList('students', {})
        .then((data) => data.data)
        .then((students) =>
          students.map((st) => {
            return {
              id: String(st.id),
              fullName: st.fullName,
              avatar: st.avatar,
              age: st.age,
            }
          }),
        )
    }
    let destMut = { ...columns.dest }
    let destStudent = destMut.items
    if (destStudent.length > 0) {
      classmembersList = classmembersList.filter(
        (cm) => !destStudent.map((s) => String(s.id)).includes(cm.id),
      )
    }
    let sourceMut = { ...columns.source }
    sourceMut.items = classmembersList

    // let destMut = { ...columns.dest }
    // destMut.items = []

    setColumns({ ...columns, source: sourceMut })
    setOrgColumns({ source: sourceMut, dest: destMut })
  }, [classId])


  return (
    <> <div style={{ marginRight: 30, paddingRight: 12 }}>

      <SelecInputClassList
        classId={classId}
        setclassId={setclassId}

      />
      <div style={{ marginBottom: 10 }} />
      <TextField
        label="Name Filter"
        value={inputfilter}
        onChange={event => {
          setInputfilter(event.target.value)
          let newsource = Object.assign({}, org.source);

          if (inputfilter.length > 1) {
            newsource.items = newsource.items.filter(column => column.fullName.indexOf(inputfilter) > -1)
          }
          setColumns({ source: newsource, dest: columns.dest })
        }}
        autoFocus
      />

    </div>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {columns &&
          Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                key={columnId}
              >
                <Typography variant="h6" gutterBottom>
                  {column.name} {column.items.length}

                </Typography>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? 'lightblue'
                              : 'lightgrey',
                            padding: 4,
                            width: 300,
                            height: 500,
                            overflow: 'auto',
                          }}
                        >
                          {column.items &&
                            column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: 'none',
                                          padding: 16,
                                          margin: '0 0 2px 0',
                                          //  minHeight: '50px',
                                          backgroundColor: snapshot.isDragging
                                            ? '#263B4A'
                                            : '#456C86',
                                          color: 'white',
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <FullNameField
                                          fullName={item.fullName}
                                          avatar={item.avatar}
                                        />
                                      </div>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                          {provided.placeholder}
                        </div>
                      )
                    }}
                  </Droppable>
                </div>
              </div>
            )
          })}
      </DragDropContext></>
  )
}

export default classMemberCreate
