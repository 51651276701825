import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import {
  DateField, Labeled, Show, ShowProps, SimpleShowLayout, TextField, useTranslate
} from 'react-admin';
import { Review } from '../types';
import CustomerReferenceField from './CustomerReferenceField';

interface Props extends ShowProps<Review> {
  onCancel: () => void;
}

const ReviewShow = ({ onCancel, ...props }: Props) => {
  const translate = useTranslate();
  return (
    <Show {...props}>
      <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
        <SimpleShowLayout>
          <Stack direction="row" p={2}>
            <Typography variant="h6" flex="1">
              {translate('resources.smss.detail')}
            </Typography>
            <IconButton onClick={onCancel} size="small">
              <CloseIcon />
            </IconButton>
          </Stack>

          <Grid container rowSpacing={1} mb={1}>
            <Grid item xs={6}>
              <Labeled>
                <CustomerReferenceField source="fromId" link={false} />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled>
                <CustomerReferenceField source="toId" link={false} />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled>
                <DateField source="createdAt" />
              </Labeled>
            </Grid>
            <Grid item xs={6}>
              <Labeled>
                <DateField source="actionAt" />
              </Labeled>
            </Grid>

          </Grid>
          <TextField source="body" fullWidth />

        </SimpleShowLayout>
      </Box>
    </Show>
  );
};

export default ReviewShow;
