import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { Card, CardContent } from '@mui/material';
import { endOfYesterday, startOfMonth, startOfWeek, subMonths, subWeeks } from 'date-fns';
import { DateInput, FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const Aside = () => {
  return (
    <Card
      sx={{
        display: { xs: 'none', md: 'block' },
        order: -1,
        width: '15em',
        mr: 2,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch />

        <FilterList
          label="resources.files.filters.file_type"
          icon={<LocalOfferIcon />}
        >
          <FilterListItem
            label="PDF"
            value={{
              fileType: 'PDF',
            }}
          />
          <FilterListItem
            label="Image"
            value={{
              fileType: 'IMAGE',
            }}
          />

          <FilterListItem
            label="Video"
            value={{
              fileType: 'VIDEO',
            }}
          />
          <FilterListItem
            label="Word"
            value={{
              fileType: 'WORD',
            }}
          />
          <FilterListItem
            label="Excel"
            value={{
              fileType: 'EXCEL',
            }}
          />
          <FilterListItem
            label="PowerPoint"
            value={{
              fileType: 'POWERPOINT',
            }}
          />
        </FilterList>
        <FilterList
          label="resources.customers.filters.last_visited"
          icon={<AccessTimeIcon />}
        >
          <FilterListItem
            label="resources.customers.filters.today"
            value={{
              lastSentGte: endOfYesterday().toISOString().slice(0, 10),
              lastSentLte: undefined,
            }}
          />
          <FilterListItem
            label="resources.customers.filters.this_week"
            value={{
              lastSentGte: startOfWeek(new Date()).toISOString().slice(0, 10),
              lastSentLte: undefined,
            }}
          />
          <FilterListItem
            label="resources.customers.filters.last_week"
            value={{
              lastSentGte: subWeeks(
                startOfWeek(new Date()),
                1
              ).toISOString().slice(0, 10),
              lastSentLte: startOfWeek(new Date()).toISOString().slice(0, 10),
            }}
          />
          <FilterListItem
            label="resources.customers.filters.this_month"
            value={{
              lastSentGte: startOfMonth(new Date()).toISOString().slice(0, 10),
              lastSentLte: undefined,
            }}
          />
          <FilterListItem
            label="resources.customers.filters.last_month"
            value={{
              lastSentGte: subMonths(
                startOfMonth(new Date()),
                1
              ).toISOString().slice(0, 10),
              lastSentLte: startOfMonth(new Date()).toISOString().slice(0, 10),
            }}
          />
          <FilterListItem
            label="resources.customers.filters.earlier"
            value={{
              lastSentGte: undefined,
              lastSentLte: subMonths(
                startOfMonth(new Date()),
                1
              ).toISOString().slice(0, 10),
            }}
          />
        </FilterList>

      </CardContent>
    </Card>
  );
};

export default Aside;
