import { jssPreset, StylesProvider } from "@material-ui/core/styles";
// function RTL(props) {
// 	return (
// 		<StyleSheetManager stylisPlugins={[stylisRTLPlugin]}>
// 			{props.children}
// 		</StyleSheetManager>
// 	);
// }
import { create } from "jss";
import rtl from "jss-rtl";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Admin, CustomRoutes, Resource } from "react-admin";
import { Route } from "react-router";
import admins from "./admins";
import authProvider from "./authProvider";
import calendar from "./calender";

import absences from "./absences";
import categories from "./categories";
import class_presences from "./class_presences";
import classes from "./classes";
import Configuration from "./configuration/Configuration";
import courses from "./courses";
import { Dashboard } from "./dashboard";
import dataProviderFactory from "./dataProvider";
import disciplines from "./disciplines";
import files from "./files";
import englishMessages from "./i18n/en";
import invoices from "./invoices";
import { Layout, Login } from "./layout";
import { lightThemertl } from "./layout/themes";
import orders from "./orders";
import parents from "./parents";
import products from "./products";
import reviews from "./reviews";
import schoolyears from "./schoolyears";
import Segments from "./segments/Segments";
import smss from "./smss";
import students from "./students";
import teachers from "./teachers";
import visitors from "./visitors";

// import ReviewFileListDesktop from './files/validation/ReviewFileListDesktop'

const i18nProvider = polyglotI18nProvider((locale) => {
  document.getElementsByTagName("html")[0].removeAttribute("dir");
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  if (locale === "ar") {
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    return import("./i18n/ar").then((messages) => messages.default);
  }
  // Always fallback on english
  return englishMessages;
}, "en");

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App = () => {
  //const [pageDir] = useStore('pageDir', 'ltr');

  return (
    <div dir="">
      <StylesProvider jss={jss}>
        <Admin
          title=""
          dataProvider={dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER || ""
          )}
          authProvider={authProvider}
          dashboard={Dashboard}
          loginPage={Login}
          layout={Layout}
          i18nProvider={i18nProvider}
          disableTelemetry
          theme={lightThemertl}
        >
          <CustomRoutes>
            <Route path="/configuration" element={<Configuration />} />
            <Route path="/segments" element={<Segments />} />
            {/* <Route path="/calender" element={<Calender />} /> */}
          </CustomRoutes>
          <Resource name="customers" {...visitors} />
          <Resource name="commands" {...orders} options={{ label: "Orders" }} />
          <Resource name="invoices" {...invoices} />
          <Resource name="products" {...products} />
          <Resource name="categories" {...categories} />
          <Resource name="reviews" {...reviews} />
          <Resource name="smss" {...smss} />
          <Resource name="admins" {...admins} />
          <Resource name="teachers" {...teachers} />
          <Resource name="parents" {...parents} />
          <Resource name="students" {...students} />
          <Resource name="calendar" {...calendar} />
          <Resource name="classes" {...classes} />
          <Resource name="courses" {...courses} />
          <Resource name="schoolyears" {...schoolyears} />

          <Resource name="files" {...files} />
          <Resource name="users" />
          <Resource name="presenceFiles" {...class_presences} />
          <Resource name="disciplines" {...disciplines} />
          <Resource name="absences" {...absences} />
        </Admin>
      </StylesProvider>
    </div>
  );
};

export default App;
