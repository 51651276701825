import VisitorIcon from '@mui/icons-material/People';

import FileCreate from './FileCreate';
import FileList from './FileListRoot';
import FileShow from './FileShow';
export default {
  icon: VisitorIcon,
  list: FileList,
  create: FileCreate,
  show: FileShow,
}
