import { usePermissions } from 'react-admin'
import AdminMenu from './adminMenu'
import ParentMenu from './parentMenu'
import StudentMenu from './studentMenu'
import TeacherMenu from './teacherMenu'

const Menu = () => {
  const { permissions } = usePermissions()
  if (!permissions) return null
  return (
    <div>
      {permissions.role === 'ADMIN' && <AdminMenu />}
      {permissions.role === 'STUDENT' && <StudentMenu />}
      {permissions.role === 'PARENT' && <ParentMenu />}
      {permissions.role === 'TEACHER' && <TeacherMenu />}
    </div>
  )
}

export default Menu
