import { FieldProps, Link, useRecordContext } from 'react-admin'
import { Parent } from '../types'
import FullNameField from './FullNameField'

const CustomerLinkField = (props: FieldProps<Parent>) => {
  const record = useRecordContext<Parent>()
  if (!record) {
    return null
  }
  return (
    <Link to={`/users/${record.id}`}>
      <FullNameField />
    </Link>
  )
}

CustomerLinkField.defaultProps = {
  source: 'id',
}

export default CustomerLinkField
