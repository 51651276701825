// @ts-nocheck
// import moment from 'moment'
// import { useCallback, useMemo, useState } from 'react'

// import events from './event'
// // Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'

// import { Grid } from '@material-ui/core'
// import { Create } from 'react-admin'

import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { RichTextInput } from 'ra-input-rich-text'
import {
  CheckboxGroupInput,
  Create,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useGetIdentity,
  useTranslate,
} from 'react-admin'


const req = [required()]
const DisciplineCreate = () => {
  const [selectedClass, setSelectedClass] = useState(-1)
  const [studentList, setstudentList] = useState([])
  const { identity, isLoading: identityLoading } = useGetIdentity()
  const transformUser = (data) => {
    data.createdBy = identity.id
    // console.log('submit', data)
    return data
  }
  const PostCreateToolbar = () => (
    <Toolbar>
      <SaveButton
        type="button"
        alwaysEnable
        label="Save 2"
        transform={transformUser}
      />
    </Toolbar>
  )

  const translate = useTranslate()
  const dataProvider = useDataProvider()

  useEffect(async () => {


    if (selectedClass > 0) {
      await dataProvider
        .getList('classMembers', { filter: { classId: selectedClass } })
        .then((data) => data.data)
        .then(async (classmembers) => {
          return await dataProvider
            .getList('students', {
              filter: { ids: classmembers.map((cm) => cm.studentId) },
            })
            .then((data) => data.data)
            .then((students) => {
              console.log('students', students)

              let abc = students.map((st) => {
                return {
                  id: String(st.id),
                  name: st.fullName,
                }


              })
              setstudentList(abc)
            })
        })
    }

  }, [selectedClass])



  return (
    <Create>
      <SimpleForm
        label="Info"
        sx={{ maxWidth: '40em' }}
        toolbar={<PostCreateToolbar />}
      >
        <Typography variant="h6" gutterBottom>
          {/* {translate('resources.customers.fieldGroups.identity')} */}
          Discipline Info
        </Typography>

        <ReferenceInput
          label="Class"
          source="classId"
          reference="classes"
          filter={{ activeSchoolYear: true }}
        >
          <SelectInput
            optionText="name"
            onChange={(e) => {
              setSelectedClass(e)
            }}
          />
        </ReferenceInput>

        <SelectInput source="studentId" choices={studentList} />

        <ReferenceInput label="cours" source="coursId" reference="courses">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <SelectInput
          label="Niveau"
          source="studentLevel"
          optionText="name"
          optionValue="id"
          choices={[
            { id: '1', name: 'Quelque difficulté ' },
            { id: '2', name: 'Résultats moyenne' },
            { id: '3', name: 'Bon éleve' },
          ]}
        />

        <ReferenceArrayInput source="labelIds" reference="labelDisciplines">
          <CheckboxGroupInput label="Disciplines" optionText="name" />
        </ReferenceArrayInput>

        <RichTextInput source="observation" />
      </SimpleForm>
    </Create>
  )
}

export default DisciplineCreate
