import VisitorIcon from '@mui/icons-material/People';
import DisciplineCreate from './disciplineCreate';
import DisciplineEdit from './disciplineEdit';
import DisciplineList from './disciplineList';
import DisciplineShow from './disciplineShow';

const resource = {
  list: DisciplineList,
  create: DisciplineCreate,
  show: DisciplineShow,
  edit: DisciplineEdit,
  icon: VisitorIcon,
};

export default resource;
