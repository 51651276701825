// @ts-nocheck
import { Typography } from '@mui/material';
import '@silevis/reactgrid/styles.css';
import moment from 'moment';
import * as React from 'react';
import {
  Show, SimpleShowLayout, useDataProvider,
  useGetIdentity, useRecordContext
} from 'react-admin';
import ClassPresenceFile from './ClassPresenceFile';
interface checkB {
  createdAt: string;
  createdAt: string;
  id: string;
}
interface Person {
  id: string;
  name: string;
  '8-9': boolean;
  '9-10': boolean;
  '10-11': boolean;
  '11-12': boolean;
  '12-13': boolean;
  '13-14': boolean;
  '14-15': boolean;
  '15-16': boolean;
  '16-17': boolean;
  '17-18': boolean;
  meta?: [checkB];
}

const setPresence = async (dataProvider, presenceFilesId) => {
  const presenceFile = await dataProvider
    .getList('presenceFiles', {
      filter: { ids: [presenceFilesId] },
    })
    .then((data) => data.data);
  if (presenceFile.length < 1) return;

  const classId = presenceFile[0].classId;
  ////console.log('presenceFile', presenceFile)
  const absenceListDB = await dataProvider
    .getList('absences', {
      filter: { presenceFilesId: presenceFilesId },
    })
    .then((data) => data.data);

  ////console.log('absenceListDB', absenceListDB)

  const studentIds = await dataProvider
    .getList('classMembers', {
      filter: { classId },
    })
    .then((response) => response.data)
    .then((data) => data.map((s) => s.studentId));

  if (studentIds.length < 1) return;
  // const classMembersPresence = classMembers.map(s=>{

  // })
  ////console.log('studentIds', studentIds)

  const classMembersPresence = await dataProvider
    .getMany('students', {
      ids: studentIds,
    })
    .then(({ data: students }) =>
      students.map((s) => ({
        id: s.id.toString(),
        name: `${s.firstName} ${s.lastName}`,
        '8-9': true,
        '9-10': true,
        '10-11': true,
        '11-12': true,
        '12-13': true,
        '13-14': true,
        '14-15': true,
        '15-16': true,
        '16-17': true,
        '17-18': true,
        meta: [],
      })),
    );

  for (let i = 0; i < absenceListDB.length; i++) {
    let abs = classMembersPresence.findIndex(
      (s) => s.id === absenceListDB[i].studentId,
    );
    ////console.log('abs', abs)
    if (abs >= 0) {
      classMembersPresence[abs][
        `${absenceListDB[i].absenceHour}-${absenceListDB[i].absenceHour + 1}`
      ] = false;

      classMembersPresence[abs].meta.push({
        id: `${absenceListDB[i].absenceHour}-${absenceListDB[i].absenceHour + 1
          }`,
        createdAt: absenceListDB[i].createdAt,
        createdBy: absenceListDB[i].createdBy,
      });
    }
  }
  //console.log('classMembersPresence', classMembersPresence);

  // setPeople(classMembersPresence)
  return classMembersPresence;
};
function ShowClassPresenceForm({ people, setPeople }) {
  const dataProvider = useDataProvider();

  const record = useRecordContext();

  React.useEffect(async () => {
    if (!record) return;

    const list = await setPresence(dataProvider, record.id.toString());
    setPeople(list);
    if (people.length !== 0) return;
  }, []);

  //if (people.length === 0) return <div />
  return <ClassPresenceFile people={people} setPeople={setPeople} />;
}

const ShowClassPresence = (props) => {
  const [people, setPeople] = React.useState<Person[]>([]);
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const transformData = (data) => {
    let created_by = identity.id + 'aa';
    let created_at = moment().format('YYYY-MM-DD');

    let absence = [];
    people.forEach((p, id) => {
      for (let i = 8; i < 19; i++) {
        let idx = `${i}-${i + 1}`;
        if (p[idx] === false) {
          let metaIdx = p.meta.findIndex((x) => x.id === idx);
          if (metaIdx >= 0) {
            created_by = p.meta[metaIdx].createdBy;
            created_at = p.meta[metaIdx].createdAt;
          } else {
            created_by = identity.id;
            created_at = moment().format('YYYY-MM-DD');
          }

          absence.push({
            presenceFilesId: data.id,
            studentId: p.id,
            absenceHour: i,
            createdBy: created_by,
            createdAt: created_at,
          });
        }
      }
    });

    return { id: data.id, data: absence };
  };


  return (

    <Show title={<PresenceFileTitle />} actions={false}>
      <SimpleShowLayout >


        <ShowClassPresenceForm people={people} setPeople={setPeople} />
      </SimpleShowLayout>
    </Show>
  );
};

const PresenceFileTitle = () => {
  const record = useRecordContext();
  return record ? (
    <Typography
      variant="h6"
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      component="div"
    // sx={{ margin: '5px 0' }}
    >

      {record.createdAt} ({record.id})
    </Typography>
  ) : null;
};

const FormattedNumberField = ({ source }) => {
  const record = useRecordContext();
  return <span style={{ marginRight: '5px' }}>{record.name} - </span>;
};
export default ShowClassPresence;
