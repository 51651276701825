import Typography from '@mui/material/Typography'
const CustomEvent = (props) => {
  const { title } = props
  const tmp = title.split('##')
  return (
    <div>
      {tmp.length === 0 ? (
        { title }
      ) : (
        <div>
          <Typography variant="button" display="block">
            {tmp[0]}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {tmp[1]}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default CustomEvent
