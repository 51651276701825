import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Card, CardContent } from '@mui/material'
import {
  endOfYesterday,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns'
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin'
import VisitorIcon from '@mui/icons-material/People';
const Aside = () => (
  <Card
    sx={{
      display: {
        xs: 'none',
        md: 'block',
      },
      order: -1,
      flex: '0 0 15em',
      mr: 2,
      mt: 8,
      alignSelf: 'flex-start',
    }}
  >
    <CardContent sx={{ pt: 1 }}>
      <FilterLiveSearch />

      <FilterList
        label="resources.customers.filters.last_visited"
        icon={<AccessTimeIcon />}
      >
        <FilterListItem
          label="resources.customers.filters.today"
          value={{
            lastSeenGte: endOfYesterday().toISOString().slice(0, 10),
            lastSeenLte: undefined,
          }}
        />
        <FilterListItem
          label="resources.customers.filters.this_week"
          value={{
            lastSeenGte: startOfWeek(new Date()).toISOString().slice(0, 10),
            lastSeenLte: undefined,
          }}
        />
        <FilterListItem
          label="resources.customers.filters.last_week"
          value={{
            lastSeenGte: subWeeks(startOfWeek(new Date()), 1)
              .toISOString()
              .slice(0, 10),
            lastSeenLte: startOfWeek(new Date()).toISOString().slice(0, 10),
          }}
        />
        <FilterListItem
          label="resources.customers.filters.this_month"
          value={{
            lastSeenGte: startOfMonth(new Date()).toISOString().slice(0, 10),
            lastSeenLte: undefined,
          }}
        />
        <FilterListItem
          label="resources.customers.filters.last_month"
          value={{
            lastSeenGte: subMonths(startOfMonth(new Date()), 1)
              .toISOString()
              .slice(0, 10),
            lastSeenLte: startOfMonth(new Date()).toISOString().slice(0, 10),
          }}
        />
        <FilterListItem
          label="resources.customers.filters.earlier"
          value={{
            lastSeenGte: undefined,
            lastSeenLte: subMonths(startOfMonth(new Date()), 1)
              .toISOString()
              .slice(0, 10),
          }}
        />
      </FilterList>

      <FilterList
        label="resources.customers.filters.user.name"
        icon={<VisitorIcon />}
      >
        <FilterListItem
          label="resources.customers.filters.user.all"
          value={{
            status: 0,
          }}
        />

        <FilterListItem
          label="resources.customers.filters.user.active"
          value={{
            status: 1,
          }}
        />
        <FilterListItem
          label="resources.customers.filters.user.archived"
          value={{
            status: 2,
          }}
        />
      </FilterList>

    </CardContent>
  </Card>
)

export default Aside
