//@ts-nocheck
import { useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  DateInput,
  FilterButton,
  List,
  TextField,
  TopToolbar,
  useDataProvider,
  useGetIdentity,
  useGetOne,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { Absence } from "../types";

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const AbsenceList = () => {
  const [defultFilter, setdefultFilter] = useState({});
  // const [studentsList, setStudentsList] = useState([]);
  const { identity } = useGetIdentity();
  const { permissions } = usePermissions();

  const dataProvider = useDataProvider();

  useEffect(() => {
    if (defultFilter && defultFilter.studentIds) return;
    if (!permissions) return;
    if (!identity) return;
    let filter = {};
    if (permissions.role === "Admin") return;

    if (permissions.role === "Student") setdefultFilter({ studentIds: [-1] });

    if (permissions.role === "PARENT") {
      filter = { parentId: identity.id };
    }

    dataProvider
      .getList("students", {
        filter,
      })
      .then((data) => {
        // setStudentsList(data.data);
        let studentsList = data.data;
        if (studentsList.length > 0) {
          let studentsIds = studentsList.map((x) => x.id);
          setdefultFilter({ studentIds: studentsIds });
          //   console.log("defultFilter", defultFilter);
        }
      });
  }, [permissions, identity, defultFilter]);

  //console.log("defultFilter", defultFilter);

  return (
    <List
      actions={<ListActions />}
      filters={
        permissions && permissions.role === "Admin"
          ? AdminAbsenceFilters
          : AbsenceFilters
      }
      filterDefaultValues={defultFilter}
      sort={{ field: "last_seen", order: "DESC" }}
      perPage={25}
    >
      <Datagrid
        optimized
        bulkActionButtons={false}
        sx={{
          "& .column-groups": {
            md: { display: "none" },
            lg: { display: "table-cell" },
          },
        }}
      >
        <CustomerReferenceField />

        <DateField source="createdAt" />
        <TextField source="absenceHour" />
      </Datagrid>
    </List>
  );
};
const CustomerReferenceField = () => {
  const record = useRecordContext<Absence>();

  if (!record) return null;
  const { data: std } = useGetOne("students", { id: record.studentId });

  if (!std) return null;

  return <div>{std.fullName} </div>;
};

const AbsenceFilters = [
  // <SearchInput source="q" alwaysOn />,

  <DateInput source="dateGte" label="Date Start" />,
  <DateInput source="dateLte" label="Date End" />,
];
// const AdminAbsenceFilters = [
//   <SearchInput source="q" alwaysOn />,
//   <ReferenceInput source="studentId" reference="students" label="Student">
//     <SelectInput
//       optionText={(choice?: Student) =>
//         choice?.id // the empty choice is { id: '' }
//           ? `${choice.fullName} `
//           : ""
//       }
//     />
//   </ReferenceInput>,

//   <DateInput source="dateGte" label="Date Start" />,
//   <DateInput source="dateLte" label="Date End" />,
// ];
export default AbsenceList;
