import Box from '@mui/material/Box'
import { useState } from 'react'
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate,
} from 'react-admin'
import reviews from '../../reviews'

type MenuName =
  | 'menuCatalog'
  | 'menuSales'
  | 'menuCustomers'
  | 'menuUsers'
  | 'menuClasses'
  | 'menuUsers2'

const StudentMenu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
    menuUsers: true,
    menuUsers2: true,
    menuClasses: true,
  })
  const translate = useTranslate()
  const [open] = useSidebarState()

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />

      <MenuItemLink
        to="/files"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.files.name`, {
          smart_count: 2,
        })}
        leftIcon={<reviews.icon />}
        dense={dense}
      />
    </Box>
  )
}

export default StudentMenu
