import { Typography } from '@mui/material'
import { useTranslate } from 'react-admin'

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate()

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  )
}
export default SectionTitle
