import Box from '@mui/material/Box';
import { useState } from 'react';
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate
} from 'react-admin';
import reviews from '../../reviews';
import visitors from '../../visitors';
import SubMenu from './../SubMenu';

type MenuName =
  | 'menuCatalog'
  | 'menuSales'
  | 'menuCustomers'
  | 'menuUsers'
  | 'menuClasses'
  | 'menuUsers2';

const AdminMenu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
    menuUsers: true,
    menuUsers2: true,
    menuClasses: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />

      <SubMenu
        handleToggle={() => handleToggle('menuUsers')}
        isOpen={state.menuUsers}
        name="pos.menu.users"
        icon={<visitors.icon />}
        dense={dense}

        color="blue"

      >
        <MenuItemLink
          to="/admins"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.admins.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/teachers"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.teachers.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/parents"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.parents.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/students"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.students.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle('menuClasses')}
        isOpen={state.menuClasses}
        name="pos.menu.class"
        icon={<visitors.icon />}
        dense={dense}
        color="green"
      >
        <MenuItemLink
          to="/schoolyears"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.year.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/classes"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.classes.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/courses"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.courses.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/presencefiles"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.presences.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
        />

        <MenuItemLink
          to="/disciplines"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.disciplines.name`, {
            smart_count: 2,
          })}
          leftIcon={<visitors.icon />}
          dense={dense}
          color="orange"
        />
      </SubMenu>
      <MenuItemLink
        to="/smss"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.smss.name`, {
          smart_count: 2,
        })}
        leftIcon={<reviews.icon />}
        dense={dense}
        sx={{ color: "orange" }}
      />
      <MenuItemLink
        to="/files"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.files.name`, {
          smart_count: 2,
        })}
        leftIcon={<reviews.icon />}
        dense={dense}
        sx={{ color: "red" }}
      />
    </Box>
  );
};

export default AdminMenu;
