import { Typography } from "@mui/material";
import { useGetOne } from "react-admin";

const DragableItem = (props) => {
  const { handleDragStart, record, classId } = props;
  const {
    data: cours,
    isLoading,
    error,
  } = useGetOne("courses", {
    id: record.competenceId,
  });
  if (isLoading) {
    return <p>Loading</p>;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <div
      draggable="true"
      onDragStart={() =>
        handleDragStart({
          classId,
          teacherId: record.id,
          title: cours.name + "##" + record.firstName + " " + record.lastName,
          name: cours.name + "##" + record.firstName + " " + record.lastName,
          bgColor: cours.color,
        })
      }
    >
      <div
        style={{
          backgroundColor: cours.color,
          borderRadius: "5px",
          opacity: 0.8,
          color: "black",
          border: "1px",
          display: "block",
          padding: "5px",
          marginBottom: "5px",
          maxWidth: "150px",
        }}
      >
        <Typography variant="button" display="block">
          {cours.name}
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          {record.firstName} {record.lastName}
        </Typography>
      </div>
    </div>
  );
};

export default DragableItem;
