import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import {
  Create,
  DateInput,
  ImageField,
  ImageInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useTranslate,
} from "react-admin";
import PasswordInputCustom from "../commun/PasswordInputCustom";

export const validateForm = (
  values: Record<string, any>
): Record<string, any> => {
  const errors = {} as any;
  if (!values.firstName) {
    errors.firstName = "ra.validation.required";
  }
  if (!values.lastName) {
    errors.lastName = "ra.validation.required";
  }

  return errors;
};
function formatLogo(value) {
  // console.log('formatLogo', value)
  if (!value || typeof value === "string") {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
}

const convertFileToBase64 = (file) =>
  new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

const VisitorCreate = () => {
  const [password, setPassword] = useState("password");
  const translate = useTranslate();
  const transformUser = async (data) => {
    //  console.log('submit', data)
    let avatarObj = data.avatar;
    let image: string | ArrayBuffer = data.avatar;
    if (
      (typeof avatarObj === "object" || typeof avatarObj === "function") &&
      avatarObj !== null
    ) {
      // Path to the image
      image = await convertFileToBase64(avatarObj).then((imgB64) => {
        return imgB64;
      });
    }

    let result: { [key: string]: any } = {
      address: data.address,
      avatar: image,
      birthDate: data.birthDate,

      firstName: data.firstName,
      lastName: data.lastName,

      phoneNumber: data.phoneNumber,
      postalCode: data.postalCode,
      role: data.role,
      state: data.state,
      username: data.username,
      password,
    };

    return result;
  };

  const PostCreateToolbar = () => (
    <Toolbar>
      <SaveButton
        type="button"
        alwaysEnable
        label="Save"
        transform={transformUser}
      />
    </Toolbar>
  );

  return (
    <Create>
      <SimpleForm validate={validateForm} toolbar={<PostCreateToolbar />}>
        <Grid container width={{ xs: "100%", xl: 800 }} spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.customers.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="firstName" isRequired fullWidth />
              </Box>
              <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="lastName" isRequired fullWidth />
              </Box>
            </Box>
            <TextInput type="username" source="username" fullWidth />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput source="birthDate" fullWidth helperText={false} />
              </Box>
              {/* <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} /> */}
              <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="phoneNumber" fullWidth helperText={false} />
              </Box>
            </Box>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.customers.fieldGroups.address")}
            </Typography>
            <TextInput
              source="address"
              multiline
              fullWidth
              helperText={false}
            />
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="state" fullWidth helperText={false} />
              </Box>
              <Box flex={2}>
                <TextInput source="postalCode" fullWidth helperText={false} />
              </Box>
            </Box>

            <Box mt="1em" />

            <Typography variant="h6" gutterBottom>
              {translate("resources.customers.fieldGroups.change_password")}
            </Typography>
            <PasswordInputCustom
              password={password}
              setPassword={setPassword}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.customers.fieldGroups.stats")}
            </Typography>

            <ImageInput
              format={formatLogo}
              source="avatar"
              label="Related pictures"
              accept="image/*"
              multiple={false}
            >
              <ImageField
                source="url"
                title="title"
                sx={{
                  "& .RaImageField-image": {
                    width: "250px",
                  },
                }}
              />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label as string)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default VisitorCreate;
