import * as React from 'react'
import { useTranslate } from 'react-admin'

import CardWithIcon from './CardWithIcon'

interface Props {
  value?: number
  icon: React.FC<any>
  iconColor: string
  title: string
  link: string
}

const NbCard = (props: Props) => {
  const { value, icon, iconColor, title, link } = props
  const translate = useTranslate()
  return (
    <CardWithIcon
      to={link}
      icon={icon}
      iconColor={iconColor}
      title={translate(title)}
      subtitle={value}
    />
  )
}

export default NbCard
