import { Viewer, Worker } from '@react-pdf-viewer/core';
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { useRecordContext } from 'react-admin';
// import { pdfjs } from 'react-pdf'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useEffect } from 'react';
const FilePdf = () => {
  const record = useRecordContext();
  if (!record) return null;

  const base64toBlob = (data: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.slice(
      'data:application/pdf;base64,'.length,
    );
    //console.log('base64WithoutPrefix', base64WithoutPrefix);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  useEffect(() => {

    if (record.fileType === 'PDF' || record.fileType === 'IMAGE') return;


    window.location.replace(record.body);



  }, []);


  if (record.fileType === 'PDF') {
    const blob = base64toBlob(record.body);

    const url = URL.createObjectURL(blob);
    return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.js">
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '750px',
          }}
        >
          {record.name}

          <Viewer
            fileUrl={url}

          // plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </Worker>
    );
  } else if (record.fileType === 'IMAGE') {

    return <img src={record.body} />;
  } else return null;
};
export default FilePdf;
