//@ts-nocheck
import {
  ArrayInput,
  AutocompleteArrayInput,
  Create,
  FileField,
  FileInput,
  Loading,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  Toolbar,
  useDataProvider,
  useGetList,
  useNotify,
  useRedirect
} from 'react-admin';
import { useState, useEffect } from 'react';
import './style.css';
import { MenuItem, Select } from '@material-ui/core';
const convertFileToBase64 = (file) =>
  new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });
const FileCreate = (props) => {
  const transformFile = async (data) => {

    let fileToUload = data.file;
    let body: string | ArrayBuffer = data.file;
    let name: string = '';
    let fileType: string = 'PDF';
    if (
      (typeof fileToUload === 'object' || typeof fileToUload === 'function') &&
      fileToUload !== null
    ) {
      // Path to the body

      body = await convertFileToBase64(fileToUload).then((b64) => {
        return b64;
      });
      name = data.file.title;
      if (fileToUload.rawFile.type.indexOf('image') >= 0) fileType = 'IMAGE';
    }

    let result: { [key: string]: any; } = {
      name,
      body,
      fileType,
    };

    if (typeof data.userIds !== 'undefined') {
      result = { ...result, userIds: data.userIds };
    }
    if (typeof data.classIds !== 'undefined') {
      result = { ...result, classIds: data.classIds };
    }

    return result;
  };

  const notify = useNotify();
  const redirect = useRedirect();

  const transformFile2 = async (data) => {
    console.log('data', data)
    let filesToUload = data.file;
    let fileList = []
    if (typeof filesToUload !== 'undefined') {
      fileList = await Promise.all(filesToUload.map(async (file) => {


        let body: string | ArrayBuffer = file;
        let name: string = '';
        let fileType: string = 'PDF';
        if (
          (typeof file === 'object' || typeof file === 'function') &&
          file !== null
        ) {
          // Path to the body

          body = await convertFileToBase64(file).then((b64) => {
            return b64;
          });
          name = file.title;
          if (file.rawFile.type.indexOf('image') >= 0) fileType = 'IMAGE';
        }

        return {
          name,
          body,
          fileType,
        };
      }));
    }
    fileList = fileList.concat(data.items);

    let result = {};
    if (typeof data.userIds !== 'undefined' && data.userIds.length > 0) {
      result = { fileList, userIds: data.userIds };
    } else {
      result = { fileList, classIds: [classid] };
    }

    return result;
  };
  const [classid, setClassid] = useState('')




  const FileCreateToolbar = () => (
    <Toolbar>
      <SaveButton
        type="button"
        alwaysEnable
        label="Save"
        transform={transformFile2}
      />
    </Toolbar>
  );
  const onSuccess = (data) => {
    notify(`Post created successfully`); // default message is 'ra.notification.created'
    redirect('list', 'files');
  };

  const onError = (data) => {

    redirect('list', 'files');
  };
  return (
    <Create {...props} mutationOptions={{ onSuccess, onError }}>
      <SimpleForm toolbar={<FileCreateToolbar />}>


        <ClassList classid={classid} setClassid={setClassid} />
        <StudentList classId={classid} />

        <FileInput
          source="file"
          label="Upload files"
          accept="image/*,application/pdf"
          multiple
          maxSize={5000000}
        >
          <FileField source="src" title="title" />
        </FileInput>

        <ArrayInput source="items" label="Link files">
          <SimpleFormIterator fullWidth inline className="inline_block" >

            <TextInput source="name" helperText={false} label="name" sx={{ marginRight: "10px" }} />
            <TextInput source="body" helperText={false} label="Url" sx={{ marginRight: "10px" }} />
            <SelectInput source="fileType" label="file Type" choices={[
              { id: 'VIDEO', name: 'Video' },
              { id: 'WORD', name: 'Word' },
              { id: 'EXCEL', name: 'Excel' },
              { id: 'POWERPOINT', name: 'PowerPoint' },
            ]} />

          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create >
  );
};
const FullNameField = ({ record }) => (
  <span>
    {record.firstName} {record.lastName}
  </span>
);

const ClassList = ({ classid, setClassid }) => {


  const { data, total, isLoading, error } = useGetList('classes', {
    filter: { activeSchoolYear: true },
  });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }
  return (
    <>

      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={classid}
        label="Age"
        onChange={event => {

          console.log('classId', event.target)
          setClassid(event.target.value)
        }}
      >
        <MenuItem key="-1" value="">ALL</MenuItem>
        {data && data.map(el => <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>)}

      </Select>
    </>
  );
};

const StudentList = ({ classId }) => {
  const dataProvider = useDataProvider();

  const [students, setstudents] = useState([])


  useEffect(async () => {

    if (classId !== undefined && classId !== '') {

      dataProvider
        .getList("classMembers", {
          filter: { classId },
        })
        .then(async (res) => res.data).then(async (data) => {



          if (data.length > 0) {
            await dataProvider
              .getMany("students", {
                ids: [...new Set(data?.map((x) => x.studentId))],
              })
              .then((res) => setstudents(res.data));
          } else {
            setstudents([])
          }
        });
    } else {
      dataProvider
        .getList("students", {

        })
        .then(async (res) => setstudents(res.data));

    }

  }, [classId]);


  return (
    <>
      <SelectArrayInput
        label="Users"
        // parse={value =>
        //   value && value.map(v => ({ id: v }))
        // }
        // format={value => value && value.map(v => v.id)}
        source="userIds"
        choices={students}
        optionText={(record) => <FullNameField record={record} />}
        optionValue="id"
      />

    </>
  );
};

export default FileCreate;
