import { Theme, useMediaQuery } from '@mui/material'
import { Datagrid, DateField, DateInput, List, SearchInput } from 'react-admin'
import VisitorListAside from '../commun/VisitorListAside'
import CustomerLinkField from './CustomerLinkField'
import MobileGrid from './MobileGrid'
import BulkAcceptButton from './BulkAcceptButton'
import BulkRejectButton from './BulkRejectButton'
import rowStyle from './rowStyle'

const visitorFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="lastSeenGte" />,
  <DateInput source="createdAt" />,
]
const ReviewsBulkActionButtons = () => (
  <>
    <BulkAcceptButton />
    <BulkRejectButton />

  </>
);
const VisitorList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  return (
    <List
      filters={isSmall ? visitorFilters : undefined}
      sort={{ field: 'last_seen', order: 'DESC' }}
      perPage={25}
      aside={<VisitorListAside />}
    >
      {isXsmall ? (
        <MobileGrid />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
          bulkActionButtons={<ReviewsBulkActionButtons />}
          rowStyle={rowStyle}
        >
          <CustomerLinkField />

          <DateField source="createdAt" />
          <DateField source="lastSeen" />
        </Datagrid>
      )}
    </List>
  )
}

export default VisitorList
