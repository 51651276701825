// @ts-nocheck
// import moment from 'moment'
// import { useCallback, useMemo, useState } from 'react'

// import events from './event'
// // Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'

// import { Grid } from '@material-ui/core'
// import { Create } from 'react-admin'

import { Typography } from '@mui/material';
import { useState } from 'react';

import { RichTextInput } from 'ra-input-rich-text';
import {
  CheckboxGroupInput,
  Edit,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import FullNameField from './FullNameField2';

const req = [required()];
const DisciplineEdit = () => {
  const [selectedClass, setSelectedClass] = useState(-1);
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const transformUser = (data) => {
    data.createdBy = identity.id;
    //console.log('submit', data);
    return data;
  };
  const PostCreateToolbar = () => (
    <Toolbar>
      <SaveButton
        type="button"
        alwaysEnable
        label="Save 2"
        transform={transformUser}
      />
    </Toolbar>
  );

  const translate = useTranslate();
  return (
    <Edit>
      <SimpleForm
        label="Info"
        sx={{ maxWidth: '40em' }}
        toolbar={<PostCreateToolbar />}
      >
        <Typography variant="h6" gutterBottom>
          {/* {translate('resources.customers.fieldGroups.identity')} */}
          Discipline Info
        </Typography>

        <ReferenceInput
          label="Class"
          source="classId"
          reference="classes"
          filter={{ activeSchoolYear: true }}
        >
          <SelectInput
            disabled
            optionText="name"
          // onChange={(e) => {
          //   setSelectedClass(e)
          // }}
          />
        </ReferenceInput>

        <ReferenceInput
          label="Student"
          source="studentId"
          reference="students"
        >
          <SelectInput optionText={<FullNameField />} disabled />
        </ReferenceInput>

        <ReferenceInput label="cours" source="coursId" reference="courses">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <SelectInput
          label="Niveau"
          source="studentLevel"
          optionText="name"
          optionValue="id"
          choices={[
            { id: '1', name: 'Quelque difficulté ' },
            { id: '2', name: 'Résultats moyenne' },
            { id: '3', name: 'Bon éleve' },
          ]}
        />

        <ReferenceArrayInput source="levels" reference="labelDisciplines">
          <CheckboxGroupInput label="Disciplines" optionText="name" />
        </ReferenceArrayInput>

        <RichTextInput source="observation" />
      </SimpleForm>
    </Edit>
  );
};

export default DisciplineEdit;
