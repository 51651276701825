// @ts-nocheck
// import moment from 'moment'
// import { useCallback, useMemo, useState } from 'react'

// import events from './event'
// // Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'

// import { Grid } from '@material-ui/core'
// import { Create } from 'react-admin'

import { Typography } from '@mui/material';

import { ChipField, ReferenceArrayField, ReferenceField, required, RichTextField, SelectField, Show, SimpleShowLayout, SingleFieldList, TextField } from 'react-admin';

const req = [required()];
const DisciplineEdit = () => {

  return (
    <Show>
      <SimpleShowLayout>
        <Typography variant="h6" gutterBottom>
          {/* {translate('resources.customers.fieldGroups.identity')} */}
          Discipline Info
        </Typography>

        <ReferenceField
          label="Class"
          source="classId"
          reference="classes"
          link={false}
        >
          <TextField label="Class" source="name" />
        </ReferenceField>

        <ReferenceField
          label="Student"
          source="studentId"
          reference="students"

          link={false}
        // filter={{ classId: selectedClass }}
        >
          <TextField label="Student" source="fullName" />
        </ReferenceField>

        <ReferenceField label="cours" source="coursId" reference="courses" link={false}>
          <TextField label="Cours" source="name" />
        </ReferenceField>

        <SelectField
          label="Niveau"
          source="studentLevel"
          optionText="name"
          optionValue="id"
          choices={[
            { id: 1, name: 'Quelque difficulté ' },
            { id: 2, name: 'Résultats moyenne' },
            { id: 3, name: 'Bon éleve' },
          ]}
        />

        <ReferenceArrayField source="levels" reference="labelDisciplines" >
          <SingleFieldList >
            <ChipField source="name" />

          </SingleFieldList>
        </ReferenceArrayField>



        <RichTextField source="observation" />
      </SimpleShowLayout>
    </Show>
  );
};

export default DisciplineEdit;
