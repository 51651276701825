import { ReferenceField, ReferenceFieldProps } from 'react-admin'
import FullNameField from './FullNameField'

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, 'reference' | 'children'> & {
    source?: string
  },
) => (
  <ReferenceField reference="users" {...props}>
    <FullNameField />
  </ReferenceField>
)

// CustomerReferenceField.defaultProps = {
//   source: 'id',
// }

export default CustomerReferenceField
