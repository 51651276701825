import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import Toolbar from '@mui/material/Toolbar'
import { Fragment, useState } from 'react'
import {
  BooleanInput,
  DeleteButton,
  EditBase,
  EditProps,
  SaveButton,
  SimpleForm,
  TextInput,
  ToolbarProps,
  useNotify,
  useRecordContext,
  useRedirect,
  useTranslate,
} from 'react-admin'

import moment from 'moment'

import { SchoolYear } from '../types'
import DateRangeEdit from './DateRangeEdit'
import SectionTitle from './SectionTitle'
interface Props extends EditProps<SchoolYear> {
  onCancel: () => void
}

const YearEdit = ({ onCancel, ...props }: Props) => {
  const translate = useTranslate()

  // if (record) setColor(record.color)

  const [valueDate, setValueDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const transformData = (data) => {
    //console.log('data', data)
    return {
      id: data.id,
      active: data.active,
      name: data.name,
      startDate: moment(valueDate.startDate).format('YYYY-MM-DD'),
      endDate: moment(valueDate.endDate).format('YYYY-MM-DD'),
    }
  }

  const YearEditToolbar = (props: ToolbarProps<SchoolYear>) => {
    const { resource } = props
    const redirect = useRedirect()
    const notify = useNotify()

    const record = useRecordContext<SchoolYear>(props)

    if (!record) return null
    return (
      <Toolbar
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: { sm: 0 },
        }}
      >
        <Fragment>
          <SaveButton
            alwaysEnable
            mutationOptions={{
              onSuccess: () => {
                notify('ra.notification.updated', {
                  type: 'info',
                  messageArgs: { smart_count: 1 },
                  undoable: true,
                })
                redirect('list', 'schoolyears')
              },
            }}
            type="button"
            transform={transformData}
          />
          <DeleteButton record={record} resource={resource} />
        </Fragment>
      </Toolbar>
    )
  }

  return (
    <EditBase {...props}>
      <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
        <Stack direction="row" p={2}>
          <Typography variant="h6" flex="1">
            {/* {translate('resources.reviews.detail')} */}
            Year Detail
          </Typography>
          <IconButton onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Stack>
        <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<YearEditToolbar />}>
          <SectionTitle label="resources.year.fields.name" />
          <TextInput source="name" fullWidth />
          <BooleanInput label="Active" source="active" />
          <SectionTitle label="resources.year.fields.date" />
          <DateRangeEdit valueDate={valueDate} setValueDate={setValueDate} />
        </SimpleForm>
      </Box>
    </EditBase>
  )
}

export default YearEdit
