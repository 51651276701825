import VisitorIcon from '@mui/icons-material/People'
import CalenderComponent from './calenderEdit'
const Calender = CalenderComponent
const resource = {
  list: Calender,
  create: Calender,
  edit: Calender,
  show: Calender,
  icon: VisitorIcon,
}

export default resource
