// @ts-nocheck
import {
  CellChange,
  CheckboxCell,
  Column,
  DateCell,
  DropdownCell,
  NumberCell,
  OptionType,
  ReactGrid,
  Row,
  TextCell
} from '@silevis/reactgrid';
import '@silevis/reactgrid/styles.css';
import * as React from 'react';
interface checkB {
  val: boolean;
}
interface Person {
  id: string;
  name: string;
  '8-9': checkB;
  '9-10': checkB;
  '10-11': checkB;
  '11-12': checkB;
  '12-13': checkB;
  '13-14': checkB;
  '14-15': checkB;
  '15-16': checkB;
  '16-17': checkB;
  '17-18': checkB;
}

interface ColumnDefine {
  id: string;
  type: string;
  label: string;
  width: number;
  format?: Intl.DateTimeFormat;
  values?: OptionType[];
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
}

const getColumnDefine: ColumnDefine[] = () => {
  let colDef = [
    {
      id: 'id',
      type: 'text',
      label: 'id',
      width: 50,
    },
    {
      id: 'name',
      type: 'text',
      label: 'Name',
      width: 150,
    },
  ];

  for (let i = 8; i < 18; i++) {
    let col = {
      id: `${i}-${i + 1}`,
      type: 'checkbox',
      label: `${i}-${i + 1}`

      //  width: 100,
    };

    colDef.push(col);
  }

  //console.log('colDef', colDef)
  return colDef;
};
const columnDefine = getColumnDefine();

// const getPeople = (): Person[] => [
//   {
//     id: '1',
//     name: 'Thomas',
//     '8-9': true,
//     '9-10': true,
//     '10-11': true,
//     '11-12': true,
//     '12-13': true,
//     '13-14': true,
//     '14-15': true,
//     '15-16': true,
//     '16-17': true,
//     '17-18': true,
//   },
// ]

const columnTypeValueSetting = [
  { type: 'text', value: 'text' },
  { type: 'checkbox', value: 'checked' },
];
const columnTypeValueMap = Object.fromEntries(
  columnTypeValueSetting.map((item) => [item.type, item.value]),
);

const getColumns = (): Column[] =>
  columnDefine.map((item) => ({ columnId: item.id, width: item.width }));

const headerRow: Row = {
  rowId: 'header',
  cells: columnDefine.map((item) => ({ type: 'header', text: item.label })),
};

const getRows = (people: Person[]): Row[] => [
  headerRow,
  ...people.map<Row>((person, idx) => ({
    rowId: idx,
    cells: columnDefine.map((item, iid) => ({
      type: item.type,
      [columnTypeValueMap[item.type]]: person[item.id],
      meta: person[item.id].meta,
      style: !(item.type === 'checkbox' && iid + 6 === new Date().getHours())
        ? { background: '#e0e0e036' }
        : { background: '#97e1ff78' },
    })),
  })),
];

function ClassPresenceFile({ people, setPeople }) {
  // const [people, setPeople] = React.useState<Person[]>(getPeople())

  const [cellChangesIndex, setCellChangesIndex] = React.useState(() => -1);
  const [cellChanges, setCellChanges] = React.useState<
    CellChange<TextCell>[][]
  >(() => []);

  const applyNewValue = (
    changes: CellChange<
      TextCell | NumberCell | CheckboxCell | DropdownCell | DateCell
    >[],
    prevPeople: Person[],
    usePrevValue: boolean = false,
  ): Person[] => {
    changes.forEach((change) => {
      const personIndex = change.rowId;
      const fieldName = change.columnId;
      const cell = usePrevValue ? change.previousCell : change.newCell;
      columnTypeValueSetting.some((typeValueSetting) => {
        // break the cycle
        if (typeValueSetting.type === cell.type) {
          prevPeople[personIndex][fieldName] = cell[typeValueSetting.value];
          return true;
        }
        return false;
      });


    });
    return [...prevPeople];
  };

  const applyChangesToPeople = (
    changes: CellChange<TextCell>[],
    prevPeople: Person[],
  ): Person[] => {
    const updated = applyNewValue(changes, prevPeople);
    setCellChanges([...cellChanges.slice(0, cellChangesIndex + 1), changes]);
    setCellChangesIndex(cellChangesIndex + 1);
    return updated;
  };

  const rows = getRows(people);
  const columns = getColumns();

  const handleChanges = (changes: CellChange<TextCell>[]) => {
    setPeople((prevPeople) => applyChangesToPeople(changes, prevPeople));
  };

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'auto',
        width: '1300px',
        height: 'auto',
        paddingBottom: '10px',
      }}
    >
      <ReactGrid
        rows={rows}
        columns={columns}
        onCellsChanged={handleChanges}
        stickyLeftColumns={1}
        stickyLeftColumns={2}
      />
    </div>
  );
}

export default ClassPresenceFile;
