import { Box, Drawer, Theme, useMediaQuery } from '@mui/material'
import { useCallback } from 'react'
import { List } from 'react-admin'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import YearActions from './YearActions'
import YearEdit from './yearEdit'
import YearFilters from './YearFilters'
import YearListDesktop from './YearListDesktop'
import YearListMobile from './YearListMobile'

const YearList = () => {
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const location = useLocation()
  const navigate = useNavigate()

  const handleClose = useCallback(() => {
    navigate('/schoolyears')
  }, [navigate])

  const match = matchPath('/schoolyears/:id', location.pathname)

  return (
    <Box display="flex">
      <List
        sx={{
          flexGrow: 1,
          transition: (theme: any) =>
            theme.transitions.create(['all'], {
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginRight: !!match ? '400px' : 0,
        }}
        filters={YearFilters}
        perPage={25}
        sort={{ field: 'name', order: 'DESC' }}
        actions={<YearActions />}
      >
        {isXSmall ? (
          <YearListMobile />
        ) : (
          <YearListDesktop
            source="id"
            // selectedRow={match && undefined}
          />
        )}
      </List>
      <Drawer
        variant="persistent"
        open={!!match}
        anchor="right"
        onClose={handleClose}
        sx={{ zIndex: 100 }}
      >
        {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
        {!!match && (
          <YearEdit id={(match as any).params.id} onCancel={handleClose} />
        )}
      </Drawer>
    </Box>
  )
}

export default YearList
