import { useState } from 'react'
import {
  Create,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
  useRedirect,
} from 'react-admin'
import ColorPicker from './ColorPicker'
import SectionTitle from './SectionTitle'

export const validateForm = (
  values: Record<string, any>,
): Record<string, any> => {
  const errors = {} as any
  if (!values.name) {
    errors.name = 'ra.validation.required'
  }

  return errors
}

const CoursesCreate = () => {
  const [Color, setColor] = useState('#1847E0')

  const transformData = (data) => {
    //console.log('data', data)
    return {
      data: {
        ...data,
        color: Color,
      },
    }
  }
  const ClassesCreateToolbar = () => {
    const redirect = useRedirect()
    const notify = useNotify()

    return (
      <Toolbar>
        <SaveButton type="button" variant="text" transform={transformData} />
        <SaveButton
          label="post.action.save_and_add"
          mutationOptions={{
            onSuccess: (data) => {
              notify('ra.notification.created', {
                type: 'info',
                messageArgs: { smart_count: 1 },
              })

              setColor('')
              redirect(false)
            },
          }}
          type="button"
          variant="text"
          transform={transformData}
        />
      </Toolbar>
    )
  }

  return (
    <Create redirect="show">
      <SimpleForm
        toolbar={<ClassesCreateToolbar />}
        sx={{ maxWidth: 500 }}
        // Here for the GQL provider
        defaultValues={{
          name: '',
        }}
        validate={validateForm}
      >
        <SectionTitle label="resources.courses.fields.name" />
        <TextInput source="name" fullWidth />
        <SectionTitle label="resources.courses.fields.color" />
        <ColorPicker Color={Color} setColor={setColor} />
      </SimpleForm>
    </Create>
  )
}

export default CoursesCreate
