import CalenderView from "../calender/calendarView";

const StudentDashboard = () => {

  return (<div>
    <CalenderView />
  </div>
  );
};

export default StudentDashboard;
