// @ts-nocheck
// import moment from 'moment'
// import { useCallback, useMemo, useState } from 'react'

// import events from './event'
// // Storybook cannot alias this, so you would use 'react-big-calendar/lib/addons/dragAndDrop'

// import { Grid } from '@material-ui/core'
// import { Create } from 'react-admin'

import { Box, Typography } from '@mui/material'
import { useState } from 'react'

import moment from 'moment'
import {
  Edit,
  FormTab,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
  useTranslate,
} from 'react-admin'
import CalenderEdit from '../calender/calenderEdit'
import ClassMemberEdit from './classMemberEdit'
import YearCreateDialog from './YearCreateDialog'

const req = [required()]
const ClassEdit = () => {
  const [myEvents, setMyEvents] = useState([])
  const [columns, setColumns] = useState({
    source: {
      name: 'Student List',

      items: [],
    },
    dest: {
      name: 'Class',
      items: [],
    },
  })
  const transformUser = (data) => {
    //console.log('myEvents', myEvents)
    const events =
      myEvents.length > 0
        ? myEvents.map((event) => ({
          teacherId: event.teacherId,
          classId: event.classId,
          weekDay: moment(event.start).isoWeekday(),
          startTime: moment(event.start).format('HH:mm'),
          endTime: moment(event.end).format('HH:mm'),
        }))
        : []

    //console.log('columns', columns)
    const classMembers =
      columns.dest.items.length > 0
        ? columns.dest.items.map((s) => ({
          classId: parseInt(data.id),
          studentId: parseInt(s.id),
        }))
        : []

    const result = {
      data: { name: data.name, yearId: data.yearId, events, classMembers },
    }
    //console.log('submit', result)
    return result
  }
  const PostCreateToolbar = () => (
    <Toolbar>
      <SaveButton
        type="button"
        alwaysEnable
        label="Save 2"
        transform={transformUser}
      />
    </Toolbar>
  )

  const translate = useTranslate()
  return (
    <Edit mutationMode="optimistic">
      <TabbedForm
        // defaultValues={{ myEvents }}
        toolbar={<PostCreateToolbar />}
      >
        <FormTab label="Info" sx={{ maxWidth: '40em' }}>
          <Typography variant="h6" gutterBottom>
            {/* {translate('resources.customers.fieldGroups.identity')} */}
            Class Info
          </Typography>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <ReferenceInput
                label="School year"
                source="yearId"
                reference="schoolyears"
              >
                <SelectInput optionText="name" create={<YearCreateDialog />} />
              </ReferenceInput>
            </Box>

            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source="name" isRequired fullWidth />
            </Box>
          </Box>
        </FormTab>
        <FormTab label="Student List" sx={{ maxWidth: '40em' }}>
          <Typography variant="h6" gutterBottom>
            class Student
          </Typography>
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <ClassMemberEdit columns={columns} setColumns={setColumns} />
          </Box>
        </FormTab>
        <FormTab label="Calendar">
          <CalenderEdit myEvents={myEvents} setMyEvents={setMyEvents} />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default ClassEdit
