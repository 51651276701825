import { useGetIdentity, usePermissions } from "react-admin";
import AdminDashboard from "./AdminDashboard";
import StudentDashboard from "./studentDashboard";
import ParentDashboard from "./parentDashboard";

const Dashboard = () => {

    const { permissions } = usePermissions();
    const { identity } = useGetIdentity();
    if (!permissions) return null;
    if (!identity) return null;
    return (
        <div>
            {permissions.role === 'ADMIN' && <AdminDashboard />}
            {permissions.role === 'STUDENT' && <StudentDashboard />}
            {permissions.role === 'PARENT' && <ParentDashboard identity={identity} />}
            {/*    {permissions.role === 'TEACHER' && <TeacherMenu />} */}
        </div>

    );
};

export default Dashboard;