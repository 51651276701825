import {
  BulkDeleteButton,
  Datagrid,
  DateField,
  Identifier,
  TextField,
  usePermissions
} from 'react-admin';
import BulkAcceptButton from './BulkAcceptButton';
import BulkRejectButton from './BulkRejectButton';
import CustomerReferenceField from './CustomerReferenceField';
import rowStyle from './rowStyle';

export interface ReviewListDesktopProps {
  selectedRow?: Identifier;
}

const ReviewsBulkActionButtons = () => (
  <>
    <BulkAcceptButton />
    <BulkRejectButton />
    <BulkDeleteButton />
  </>
);

const ReviewListDesktopAdmin = ({ selectedRow }: ReviewListDesktopProps) => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  const bulk = permissions.role === 'ADMIN' ? <ReviewsBulkActionButtons /> : false;
  return (
    <Datagrid
      rowClick="edit"

      rowStyle={rowStyle(selectedRow)}
      optimized
      bulkActionButtons={bulk}
      sx={{
        '& .RaDatagrid-thead': {
          borderLeftColor: 'transparent',
          borderLeftWidth: 5,
          borderLeftStyle: 'solid',
        },
        '& .column-body': {
          maxWidth: '18em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <CustomerReferenceField source="fromId" link={false} />
      <CustomerReferenceField source="toId" link={false} />
      {/* <ProductReferenceField link={false} /> */}
      <DateField source="createdAt" />
      {/* <StarRatingField size="small" /> */}
      <TextField source="body" />
      <TextField source="status" />
    </Datagrid>
  );
};

export default ReviewListDesktopAdmin;
