// @ts-nocheck
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'

const SelecInputClassList = ({ classId, setclassId }) => {

  const dataProvider = useDataProvider()

  const [classes, setclasses] = useState([])

  useEffect(async () => {
    //  let result=[]
    if (classes.length > 0) return
    let classList = await dataProvider
      .getList('classes', {})
      .then((data) => data.data)
      .then(async (classes) => {
        return await dataProvider
          .getList('schoolyears', {})
          .then((data) => data.data)
          .then((yearsList) => {
            return classes.map((c) => {
              return { ...c, year: yearsList.filter((y) => y.id === c.yearId) }
            })
          })
      })



    setclasses(classList)
  }, [classes])
  const handleChange = (event: SelectChangeEvent) => {
    setclassId(event.target.value)

  }

  return (
    <Select
      labelId="demo-simple-select-autowidth-label"
      id="demo-simple-select-autowidth"
      value={classId}
      onChange={handleChange}
      autoWidth
      label="Age"
      sx={{ marginLeft: 5 }}
    >
      <MenuItem value={-1}>
        <em>ALL</em>
      </MenuItem>
      {classes &&
        classes.map((c) => (
          <MenuItem
            key={c.id}
            value={c.id}
          >{`${c.year[0].name} - ${c.name}`}</MenuItem>
        ))}
    </Select>
  )
}

export default SelecInputClassList
