// @ts-nocheck
import '@silevis/reactgrid/styles.css';
import moment from 'moment';
import * as React from 'react';
import {
  Create,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRedirect
} from 'react-admin';
import ClassPresenceFile from './ClassPresenceFile';
interface checkB {
  createdAt: string;
  createdAt: string;
  id: string;
}
interface Person {
  id: string;
  name: string;
  '8-9': boolean;
  '9-10': boolean;
  '10-11': boolean;
  '11-12': boolean;
  '12-13': boolean;
  '13-14': boolean;
  '14-15': boolean;
  '15-16': boolean;
  '16-17': boolean;
  '17-18': boolean;
  meta?: [checkB];
}

const setPresence = async (dataProvider, classId) => {
  const studentIds = await dataProvider
    .getList('classMembers', {
      filter: { classId },
    })
    .then((response) => response.data)
    .then((data) => data.map((s) => s.studentId));

  if (studentIds.length < 1) return;

  const classMembersPresence = await dataProvider
    .getMany('students', {
      ids: studentIds,
    })
    .then(({ data: students }) =>
      students.map((s) => ({
        id: s.id.toString(),
        name: `${s.firstName} ${s.lastName}`,
        '8-9': true,
        '9-10': true,
        '10-11': true,
        '11-12': true,
        '12-13': true,
        '13-14': true,
        '14-15': true,
        '15-16': true,
        '16-17': true,
        '17-18': true,
        meta: [],
      })),
    );
  return classMembersPresence;
};
function CreateClassPresenceForm({ class_id, people, setPeople }) {
  const dataProvider = useDataProvider();

  React.useEffect(async () => {
    if (class_id === '') return;
    let list = (await setPresence(dataProvider, class_id)) || [];

    //if (list.length !== 0) list = []
    setPeople(list);
  }, [class_id]);

  //if (people.length === 0) return <div />
  return <ClassPresenceFile people={people} setPeople={setPeople} />;
}

const CreateClassPresence = (props) => {
  const [people, setPeople] = React.useState<Person[]>([]);
  const [classID, setClassID] = React.useState('');
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const transformData = (data) => {
    let absence = [];
    people.forEach((p, id) => {
      for (let i = 8; i < 19; i++) {
        let idx = `${i}-${i + 1}`;
        if (p[idx] === false) {
          absence.push({
            studentId: p.id,
            absenceHour: i,
            createdBy: identity.id,
            createdAt: moment().format('YYYY-MM-DD'),
          });
        }
      }
    });
    return { classId: data.classId, data: absence };
  };

  const PresenceCreateToolbar = (props) => {
    const { resource } = props;
    const redirect = useRedirect();
    const notify = useNotify();

    return (
      <Toolbar
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: { sm: 0 },
        }}
      >
        <React.Fragment>
          <SaveButton
            alwaysEnable
            mutationOptions={{
              onSuccess: () => {
                notify('ra.notification.updated', {
                  type: 'info',
                  messageArgs: { smart_count: 1 },
                  undoable: true,
                });
                redirect('list', 'presenceFiles');
              },
            }}
            type="button"
            transform={transformData}
          />
        </React.Fragment>
      </Toolbar>
    );
  };

  return (
    <Create>
      <SimpleForm toolbar={<PresenceCreateToolbar />}>
        <ReferenceInput source="classId" reference="classes" label="Classes" filter={{ activeSchoolYear: true }}>
          <SelectInput
            onChange={(class_id) => setClassID(class_id)}
            //source="schoolyearId"
            optionText={(choice?: Class) =>
              choice?.id // the empty choice is { id: '' }
                ? `${choice.name}`
                : ''
            }
          />
        </ReferenceInput>
        <CreateClassPresenceForm
          class_id={classID}
          people={people}
          setPeople={setPeople}
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateClassPresence;
