import VisitorIcon from '@mui/icons-material/People'

import VisitorCreate from './VisitorCreate'
import VisitorEdit from './VisitorEdit'
import VisitorList from './VisitorList'

const resource = {
  list: VisitorList,
  create: VisitorCreate,
  edit: VisitorEdit,
  icon: VisitorIcon,
}

export default resource
