import { Box, Card, Divider, Typography } from '@mui/material'
import { createElement, FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

interface Props {
  icon: FC<any>
  iconColor: string
  to: string
  title?: string
  subtitle?: string | number
  children?: ReactNode
}

const CardWithIcon = (props: Props) => {
  const { icon, iconColor, title, subtitle, to, children } = props

  return (
    // @ts-ignore
    <Card
      sx={{
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
          textDecoration: 'none',
          color: 'inherit',
        },
      }}
    >
      <Link to={to}>
        <Box
          sx={{
            overflow: 'inherit',
            padding: '16px',
            // background: (theme) =>
            //   `url(${
            //     theme.palette.mode === 'dark' ? cartoucheDark : cartouche
            //   }) no-repeat`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .icon': {
              color: (theme) =>
                theme.palette.mode === 'dark' ? 'inherit' : iconColor,
            },
          }}
        >
          <Box width="3em" className="icon">
            {createElement(icon, { fontSize: 'large' })}
          </Box>
          <Box textAlign="right">
            <Typography color="textSecondary">{title}</Typography>
            <Typography variant="h5" component="h2">
              {subtitle || ' '}
            </Typography>
          </Box>
        </Box>
      </Link>
      {children && <Divider />}
      {children}
    </Card>
  )
}

export default CardWithIcon
