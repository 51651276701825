import { Datagrid, Identifier, TextField } from 'react-admin'

export interface ReviewListDesktopProps {
  selectedRow?: Identifier
}

const CoursesListDesktop = ({ selectedRow }: ReviewListDesktopProps) => (
  <Datagrid
    rowClick="edit"
    optimized
    sx={{
      '& .RaDatagrid-thead': {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
      },
      '& .column-comment': {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    }}
  >
    <TextField source="name" />
  </Datagrid>
)

export default CoursesListDesktop
