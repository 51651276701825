
import red from '@mui/material/colors/red'
import { Student } from '../types'

const rowStyle = (record: Student) => {

  let style = {}

  if (!record) {
    return style
  }

  if (!record.active)
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    }
  return style
}

export default rowStyle
