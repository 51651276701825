import {
  BulkDeleteButton,
  Datagrid,
  DateField,
  Identifier,
  List,
  TextField,
} from "react-admin";
import BulkAcceptButton from "./BulkAcceptButton";
import BulkRejectButton from "./BulkRejectButton";
import rowStyle from "./rowStyle";

import { TableCell, TableHead, TableRow } from "@mui/material";
import Aside from "../Aside";

const DatagridHeader = ({ children }) => {
  //console.log("children",children)

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>{" "}
        {/* empty cell to account for the select row checkbox in the body */}
        {children.map((child) => (
          <TableCell key={child.props.source}>{child.props.source}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export interface ReviewListDesktopProps {
  selectedRow?: Identifier;
}

const ReviewsBulkActionButtons = () => (
  <>
    <BulkAcceptButton />
    <BulkRejectButton />
    <BulkDeleteButton />
  </>
);

const ReviewListDesktop = ({ selectedRow }: ReviewListDesktopProps) => {
  //   const { data, total, isLoading } = useGetList('files', {
  //     pagination: { page: 1, perPage: 10 },
  //   })
  // const listContext = useList({ data, isLoading });
  return (
    <List aside={<Aside />}
      filter={{
        role: 'ADMIN'
      }}>
      <Datagrid
        rowStyle={rowStyle(selectedRow)}
        optimized
        bulkActionButtons={<ReviewsBulkActionButtons />}
        sx={{
          "& .RaDatagrid-thead": {
            borderLeftColor: "transparent",
            borderLeftWidth: 5,
            borderLeftStyle: "solid",
          },
          "& .column-body": {
            maxWidth: "18em",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        }}
      >
        <TextField source="name" />
        <TextField source="fileType" />
        <DateField source="createdAt" />
        <TextField source="fileStatus" />
      </Datagrid>
    </List>
  );
};

export default ReviewListDesktop;
