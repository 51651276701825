import VisitorIcon from '@mui/icons-material/People'
import ClassCreate from './classCreate'
import ClassEdit from './classEdit'
import ClassList from './classList'

const resource = {
  list: ClassList,
  create: ClassCreate,
  edit: ClassEdit,
  icon: VisitorIcon,
}

export default resource
