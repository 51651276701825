import { Show, SimpleShowLayout } from 'react-admin'
import FilePdf from './FilePdf'

const FileShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <FilePdf />
      </SimpleShowLayout>
    </Show>
  )
}
export default FileShow
