import Box from "@mui/material/Box";
import { useState } from "react";
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate,
} from "react-admin";
import reviews from "../../reviews";
import visitors from "../../visitors";

type MenuName =
  | "menuCatalog"
  | "menuSales"
  | "menuCustomers"
  | "menuUsers"
  | "menuClasses"
  | "menuUsers2";

const ParentMenu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
    menuUsers: true,
    menuUsers2: true,
    menuClasses: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />

      <MenuItemLink
        to="/absences"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.presences.name`, {
          smart_count: 2,
        })}
        leftIcon={<visitors.icon />}
        dense={dense}
      />

      <MenuItemLink
        to="/disciplines"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.disciplines.name`, {
          smart_count: 2,
        })}
        leftIcon={<visitors.icon />}
        dense={dense}
      />

      <MenuItemLink
        to="/smss"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.smss.name`, {
          smart_count: 2,
        })}
        leftIcon={<reviews.icon />}
        dense={dense}
      />
      <MenuItemLink
        to="/files"
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.files.name`, {
          smart_count: 2,
        })}
        leftIcon={<reviews.icon />}
        dense={dense}
      />
    </Box>
  );
};

export default ParentMenu;
