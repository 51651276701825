//@ts-nocheck
import { Theme, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  FilterButton,
  FunctionField,
  List,
  ReferenceField,
  ReferenceFieldProps,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
  TopToolbar,
  useDataProvider,
  useGetIdentity,
  usePermissions,
  useRecordContext,
} from "react-admin";
import { Class, Courses, Student } from "../types";

//import MobileGrid from './MobileGrid'

const ClassFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    source="classId"
    reference="classes"
    label="class"
    filter={{ activeSchoolYear: true }}
  >
    <SelectInput
      optionText={(choice?: Class) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.name}`
          : ""
      }
    />
  </ReferenceInput>,
  <ReferenceInput source="coursId" reference="courses" label="Cour">
    <SelectInput
      optionText={(choice?: Courses) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.name}`
          : ""
      }
    />
  </ReferenceInput>,
  <DateInput source="dateGte" label="Date Start" />,
  <DateInput source="dateLte" label="Date End" />,
];

const FullNameField = (props) => {
  const record = useRecordContext<Student>();
  //console.log("record", record);
  return record ? <div>{record.fullName} </div> : null;
};

const CustomerReferenceField = (
  props: Omit<ReferenceFieldProps, "reference" | "children" | "source"> & {
    source?: string;
  }
) => (
  <ReferenceField
    source="studentId"
    reference="students"
    link={false}
    {...props}
  >
    <FullNameField />
  </ReferenceField>
);

const ClassField = (props) => {
  //console.log("props", props);
  const record = useRecordContext<Class>();
  //console.log("record", record);
  return record ? <div>{record.name} </div> : null;
};

const ClassReferenceField = (
  props: Omit<ReferenceFieldProps, "reference" | "children" | "source"> & {
    source?: string;
  }
) => (
  <ReferenceField source="classId" reference="classes" link={false} {...props}>
    <FunctionField
      render={(record) => `${record.firstName} ${record.lastName}`}
    />
  </ReferenceField>
);
const DisciplineList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

  const dataProvider = useDataProvider();
  const { permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const [usersId, setUsersId] = useState([]);
  const [defaultFilter, setDefaultFilter] = useState({});
  const [action, setaction] = useState("edit");
  const [bulk, setBulk] = useState(true);

  useEffect(async () => {
    if (!permissions) return;
    if (!identity) return;
    if (permissions.role === "PARENT" || permissions.role === "TEACHER") {
      setBulk(false);
    }

    if (permissions.role === "PARENT") {
      setaction("show");
      await dataProvider
        .getList("students", {
          filter: { parentId: identity.id },
        }).then((data) => {
          setUsersId(data.data);
        });
    }
  }, [identity, permissions]);

  // //console.log(usersId);

  useEffect(async () => {
    if (!permissions) return;
    if (!identity) return;


    if (permissions.role === "PARENT") {
      console.log('usersId', usersId)
      if (usersId.length > 0) {
        setDefaultFilter({ ids: usersId.map((s) => s.id) });
      } else {
        setDefaultFilter({ ids: [-1] })
      }

    }
  }, [identity, permissions, usersId]);


  if (!permissions) return null;
  const ListActions = () => {
    // const { permissions } = usePermissions();
    if (!permissions) return null;
    return (
      <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        <FilterButton />
        {(permissions.role === "ADMIN" || permissions.role === "TEACHER") && (
          <CreateButton />
        )}
      </TopToolbar>
    );
  };



  return (
    <List
      actions={<ListActions />}
      filters={ClassFilters}
      filterDefaultValues={defaultFilter}
      sort={{ field: "last_seen", order: "DESC" }}
      perPage={25}
    >
      {isXsmall ? (
        ///    <MobileGrid />
        <div />
      ) : (
        <Datagrid
          optimized
          bulkActionButtons={bulk}
          rowClick={action}
          sx={{
            "& .column-groups": {
              md: { display: "none" },
              lg: { display: "table-cell" },
            },
          }}
        >
          <CustomerReferenceField source="studentId" />

          <ReferenceField
            source="classId"
            reference="classes"
            link={false}
            label="class"
          >
            <TextField source="name" />
          </ReferenceField>

          <ReferenceField
            source="coursId"
            reference="courses"
            link={false}
            label="cours"
          >
            <TextField source="name" />
          </ReferenceField>


          <DateField source="createdAt" />
          {(permissions.role === "ADMIN" || permissions.role === "TEACHER") && (
            <EditButton />
          )}
        </Datagrid>
      )}
    </List>
  );
};

export default DisciplineList;
