import {
    AutocompleteInput,
    DateInput,
    ReferenceInput,
    SearchInput,
    SelectInput
} from 'react-admin';
import { User } from '../types';

export const reviewFiltersFull = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput
        source="smsStatus"
        choices={[
            { id: 'ACCEPTED', name: 'Accepted' },
            { id: 'PENDING', name: 'Pending' },
            { id: 'REJECTED', name: 'Rejected' },
        ]}
    />,
    <ReferenceInput source="fromId" reference="users">
        <AutocompleteInput
            optionText={(choice?: User) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.firstName} ${choice.lastName}`
                    : ''
            }
        />
    </ReferenceInput>,
    <ReferenceInput source="toId" reference="users">
        <AutocompleteInput
            optionText={(choice?: User) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.firstName} ${choice.lastName}`
                    : ''
            }
        />
    </ReferenceInput>,
    <DateInput source="lastSentGte" />,
    <DateInput source="lastSentLte" />,
];
export const reviewFiltersLight = [
    <SearchInput source="q" alwaysOn />,
    <DateInput source="lastSentGte" />,
    <DateInput source="lastSentLte" />,
];


