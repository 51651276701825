import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import moment from 'moment'
import { useState } from 'react'

import { useCreate, useCreateSuggestionContext, useRefresh } from 'react-admin'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // the
const YearCreateDialog = () => {
  const { onCancel, onCreate } = useCreateSuggestionContext()
  const [value, setValue] = useState('')
  const [valueDate, setValueDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })

  const [create] = useCreate()

  const handleSelect = (date) => {
    setValueDate(date.selection)
    //console.log(date) // native Date object
  }
  const refresh = useRefresh()
  const handleSubmit = (event) => {
    create(
      'schoolyears',
      {
        data: {
          name: value,
          active: true,
          startDate: moment(valueDate.startDate).format('YYYY-MM-DD'),
          endDate: moment(valueDate.endDate).format('YYYY-MM-DD'),
        },
      },
      {
        onSuccess: ({ data }) => {
          setValue('')
          onCreate(data)
          refresh()
          //  redirect(false)
        },
      },
    )
    setValue('')
  }

  return (
    <Dialog open onClose={onCancel}>
      <DialogTitle>Set school year </DialogTitle>
      <form>
        <DialogContent>
          <TextField
            label="New Year name"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            autoFocus
          />
          <Typography variant="h6" gutterBottom>
            Date
          </Typography>
          <DateRange
            onChange={handleSelect}
            ranges={[valueDate]}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default YearCreateDialog
