import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  Identifier,
  Link,
  TextField,
} from 'react-admin'

export interface ReviewListDesktopProps {
  selectedRow?: Identifier
}

const redirectUrl = (record) =>
  '/classes?displayedFilters={"schoolyearId":true}&filter={"schoolyearId":"' +
  record.id +
  '"}'
const YearListDesktop = (props) => {
  return (
    <Datagrid
      //rowClick={(id, resource, record) => 'qaqqq' + id}
      optimized
      sx={{
        '& .RaDatagrid-thead': {
          borderLeftColor: 'transparent',
          borderLeftWidth: 5,
          borderLeftStyle: 'solid',
        },
        '& .column-comment': {
          maxWidth: '18em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <FunctionField
        label="Name"
        render={(record) => (
          <Link to={redirectUrl(record)}>
            {!record.active && (
              <TextField source="name" sx={{ color: 'lightgrey' }} />
            )}
            {record.active && <TextField source="name" />}
          </Link>
        )}
      />

      <DateField source="startDate" />
      <DateField source="endDate" />
      <EditButton />
    </Datagrid>
  )
}

export default YearListDesktop
