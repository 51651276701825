import { Theme, useMediaQuery } from '@mui/material'
import {
  Datagrid,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from 'react-admin'
import { SchoolYear } from '../types'

//import MobileGrid from './MobileGrid'

const ClassFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    source="schoolyearId"
    reference="schoolyears"
    label="schoolyears"
  >
    <SelectInput
      //source="schoolyearId"
      optionText={(choice?: SchoolYear) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.name}`
          : ''
      }
    />
  </ReferenceInput>,
  //<SchoolYearInput source="schoolYear" />,
]

const ClassList = () => {
  const isXsmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  return (
    <List
      filters={ClassFilters}
      sort={{ field: 'last_seen', order: 'DESC' }}
      perPage={25}
    >
      {isXsmall ? (
        ///    <MobileGrid />
        <div />
      ) : (
        <Datagrid
          optimized
          rowClick="edit"
          sx={{
            '& .column-groups': {
              md: { display: 'none' },
              lg: { display: 'table-cell' },
            },
          }}
        >
          <TextField source="name" label="class name" />

          <ReferenceField
            source="yearId"
            reference="schoolyears"
            link={false}
            label="School Years"
          >
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      )}
    </List>
  )
}

export default ClassList
