import { CSSProperties, useEffect, useState } from 'react'
import { useRecordContext } from 'react-admin'
import { ChromePicker } from 'react-color'
import { Courses } from '../types'
export interface StylesDictionary {
  [Key: string]: CSSProperties
}
const ColorPicker = ({ Color, setColor }) => {
  const [DisplayColorPicker, setDisplayColorPicker] = useState(false)
  const record = useRecordContext<Courses>()
  useEffect(() => {
    if (record) setColor(record.color)
  }, [record])

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color) => {
    setColor(color.hex)
  }

  const styles: StylesDictionary = {
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      background: `${Color}`,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: '2',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }

  return (
    <div>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {DisplayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <ChromePicker color={Color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  )
}

export default ColorPicker
