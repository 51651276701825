import { Loading, SelectInput, SelectInputProps, useGetList } from 'react-admin'

const SegmentsInput = (props: SelectInputProps) => {
  const { data, isLoading, error } = useGetList('courses', {})
  if (isLoading) {
    return <Loading />
  }
  const optionRenderer = (choice) => `${choice.name}`
  return (
    <SelectInput
      {...props}
      optionText={optionRenderer}
      optionValue="id"
      translateChoice={false}
      choices={data}
    />
  )
}
export default SegmentsInput
